import { Stars } from "react-bootstrap-icons";
import {
  group_icon,
  petrol_pump,
  rental_car,
  stars,
  mileage,
  star,
  yellow_bullet_star,
  noun_no_image,
} from "../../../assets/images";
import { Carousel } from "react-bootstrap";
import "./RentalSummaryCarousel.css";
import axiosInstance from "../../../services/axios.instance";
import { API_URL } from "../../../services/apiConfig";
import { useQuery } from "@tanstack/react-query";
import { ProductDetails } from "../../../types";

function RentalSummaryCarousel() {
  const {
    data: insuranceData,
    isLoading,
    isError,
  } = useQuery<ProductDetails[], Error>({
    queryKey: ["insuranceProducts"],
    queryFn: async () => {
      const response = await axiosInstance.get(
        API_URL.INSURANCE_PRODUCT_LISTING
      );
      console.log(response, "response");
      return response.data;
    },
  });

  return (
    <div className="col-md-12">
      <Carousel pause={false} indicators={false} className="rental-carousel">
        {insuranceData &&
          insuranceData?.map((insuranceProduct, index: number) => (
            <Carousel.Item key={index}>
              <div className="card card-custom-carousel">
                <div className="card-body  ">
                  <div className="col-md-12 text-style">
                    <h5 className=""> Rental Summary </h5>
                  </div>
                  <div className="row">
                    <div className="col-6 car-style-img">
                      {insuranceProduct?.product_details?.images?.length > 0 ? (
                        <img
                          className="img-fluid mb-2 file_image_icon"
                          src={insuranceProduct?.product_details?.images[0]}
                          alt=""
                        />
                      ) : (
                        <img
                          src={noun_no_image}
                          alt=""
                          className="img-fluid mb-2 file_image_icon"
                        />
                      )}
                    </div>

                    <div
                      className="col-6 align-content-center"
                      style={{ textAlign: "left" }}
                    >
                      <div className="rent-carmodel-text">
                        {insuranceProduct?.product_name}
                      </div>
                      <div>
                        <li style={{ color: "yellow" }}>
                          <span className="text-colour">Features </span>
                        </li>
                      </div>
                      <div className="row">
                        <div className="d-flex">
                          <div>
                            <img
                              src={group_icon}
                              alt="file_browse_icon"
                              className="img-fluid"
                            />
                            <span className="text-colour ps-1">
                              {
                                insuranceProduct?.product_details_car
                                  ?.number_of_seats
                              }
                            </span>
                          </div>
                          <div className="ms-3">
                            <img
                              src={petrol_pump}
                              alt="file_browse_icon"
                              className="img-fluid img-width-icon"
                            />
                            <span className="text-colour">
                              {insuranceProduct?.product_details_car?.fuel_type}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row pt-3">
                    <div className="col-md-12">
                      <table className="text-start table-width-custom">
                        <tbody>
                          <tr className="fontsize-li ">
                            <td className="">
                              <li style={{ color: "yellow" }}>
                                <span className="  fontsize-li">
                                  Minimum Documents{" "}
                                </span>
                              </li>
                            </td>
                            <td>
                              <li style={{ color: "yellow" }}>
                                <span className="  fontsize-li">
                                  {" "}
                                  Delivery Anywhere in Dubai
                                </span>
                              </li>
                            </td>
                          </tr>
                          <tr className="fontsize-li ">
                            <td className="">
                              <li style={{ color: "yellow" }}>
                                <span className="  fontsize-li">
                                  {" "}
                                  Service and Maintenance Free{" "}
                                </span>
                              </li>
                            </td>
                            <td>
                              <li style={{ color: "yellow" }}>
                                <span className="  fontsize-li">
                                  24/7 Roadside Assistance{" "}
                                </span>
                              </li>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={2} className="">
                              {/* <br /> */}
                              <span style={{ color: "yellow" }}>* </span>
                              <span className="fontsize3">
                                price is “0” (Charges not applicable - no
                                charges for insurance holder)
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>
          ))}
      </Carousel>
    </div>
  );
}

export default RentalSummaryCarousel;
