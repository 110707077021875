import React, { useEffect } from "react";
import { Form, useForm } from "react-hook-form";
import CustomInput from "../../CustomInput";
import CustomRadio from "../../customRadio";
import DatePicker from "react-datepicker";
import { useRef, useState } from "react";
import {
  vector_vv,
  car_key,
  vehicle_inspection_car,
} from "../../../assets/images";
import { useNavigate } from "react-router-dom";
import RentalSummaryCarousel from "../RentalSummaryCarousel";
import { useMutation, useQuery } from "@tanstack/react-query";
import axiosInstance from "../../../services/axios.instance";
import { API_URL } from "../../../services/apiConfig";
import { CustomerDetails, DocumentDetails } from "../../../types";
import AuthRules from "../../../rules/AuthRules";
import { Trash } from "react-bootstrap-icons";
import rules from "../../../rules";
import moment from "moment";
import { toast } from "react-toastify";
import { InsuranceDateComponent } from "../..";

function BookingProcess() {
  const { control, setValue, getValues } = useForm({ mode: "onChange" });

  const navigate = useNavigate();
  const [selectedOptions, setSelectedOptions] = useState<string>("");
  const [updatedOption, setUpdatedOption] = useState<string>("");
  const [insuranceDate, setInsuranceDate] = useState<string>("");
  const [selectedCompany, setSelectedCompany] = useState<string>("");
  const [selectedGarage, setSelectedGarage] = useState<string>("");
  const [selectedGarageId, setSelectedGarageId] = useState<string>("");
  const [showOtherGarageInput, setShowOtherGarageInput] = useState(false);
  const [otherGarageValue, setOtherGarageValue] = useState<string>("");

  const handleRadioChange = (value: string) => {
    setSelectedOptions(value);
  };

  const [inputValue, setInputValue] = useState("");

  const { data: suggestions = [] } = useQuery({
    queryKey: inputValue ? [inputValue] : [],
    queryFn: async () => {
      if (inputValue) {
        const response = await axiosInstance.get(
          `${API_URL.INSURANCE_COMPANY_SEARCH}?keyword=${inputValue}`
        );
        return response.data;
      }
      return [];
    },
  });

  const handleInputChange = (value: string) => {
    setInputValue(value);
  };

  //----------------------------------------------

  const [inputGarageValue, setInputGarargeValue] = useState("");

  const { data: garagesuggestions = [] } = useQuery({
    queryKey: ["garageSuggestions", inputGarageValue],
    queryFn: async () => {
      if (inputGarageValue) {
        const response = await axiosInstance.post(API_URL.GARAGE_SEARCH, {
          searchKeyword: inputGarageValue?.trim(),
          userType: "GARAGE_USER",
        });
        return response.data;
      }
      return [];
    },
    enabled: !!inputGarageValue,
  });

  const handleGarageInputChange = (value: string) => {
    setInputGarargeValue(value);
    console.log(
      "INput Garageeeeeeee Valueeeeeeeeeeeeeeeeeeeeeee",
      inputGarageValue
    );

    if (inputGarageValue === "Other Garages") {
      setShowOtherGarageInput(true);
      setSelectedGarage(otherGarageValue);
      console.log("Selected Garage:", otherGarageValue);
    } else {
      setShowOtherGarageInput(false);
      setSelectedGarage(value);
      console.log("Selected Garage:", value);
    }
  };

  const augmentedGarageSuggestions = Array.isArray(garagesuggestions)
    ? inputGarageValue
      ? [...garagesuggestions, { customer_id: -1, shop_name: "Other Garages" }]
      : garagesuggestions
    : [];

  //----------------------------------------

  const handleUpdateChange = (value: string) => {
    setUpdatedOption(value);
    if (value === "Yes") {
      navigate("/Customer/MyAccount");
    }
  };

  const handleInsuranceDateChange = (date: string) => {
    setInsuranceDate(date);
    console.log("dateeee", date);
  };

  //---------------------------------------------------------------------------------------------------
  const [expiryError, setExpiryError] = useState("");
  const [expiryDate, setExpiryDate] = useState<string | undefined>(undefined);

  const [selectedImage, setSelectedImage] = useState<string[]>([]);
  const [uploadedFile, setUploadedFile] = useState<string[]>([]);
  const [licenseFile, setLicenseFile] = useState<string[]>([]);
  const [emiratesIdFile, setEmiratesIdFile] = useState<string[]>([]);
  const [isCompanyPrefilled, setIsCompanyPrefilled] = useState<boolean>(false);

  const [uploadSuccess, setUploadSuccess] = useState(false);

  const fileInputRefLicense = useRef<HTMLInputElement>(null);
  const fileInputRefEmiratesId = useRef<HTMLInputElement>(null);

  const handleExpiryDateChange = (date: Date) => {
    if (date) {
      const currentDate = new Date();
      const oneYearLater = new Date();
      oneYearLater.setFullYear(oneYearLater.getFullYear() + 1);

      if (date == oneYearLater) {
        setExpiryError(
          "Expiry date must be at least one year from the current date."
        );
      } else {
        setExpiryDate(date.toISOString());
        setExpiryError("");
      }
    } else {
      setExpiryDate("");
      setExpiryError("");
    }
  };

  const { mutate } = useMutation({
    mutationFn: (fileList: FileList) => {
      const formData = new FormData();
      Array.from(fileList).forEach((file) => {
        formData.append("document", file);
      });
      return axiosInstance.post(API_URL.DOC_UPLOAD, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },
    onSuccess: (response) => {
      const uuid = response?.data?.uuid || null;
      setUploadedFile((prevUUIDs) => [...prevUUIDs, uuid]);
      setUploadSuccess(true);
      toast.success("Updated successfully!", {
        position: "top-right",
      });
      if (fileInputRefLicense.current) {
        fileInputRefLicense.current.value = "";
      }
      if (fileInputRefEmiratesId.current) {
        fileInputRefEmiratesId.current.value = "";
      }
    },
    onError: (error) => {
      console.error("Error uploading images", error);
      setUploadSuccess(false);
      toast.error("Error updating data. Please try again.", {
        position: "top-right",
      });
    },
  });

  const openFileInputLicense = (event: { preventDefault: () => void }) => {
    event?.preventDefault();
    fileInputRefLicense?.current?.click();
  };
  const openFileInputEmiratesId = (event: { preventDefault: () => void }) => {
    event?.preventDefault();
    fileInputRefEmiratesId?.current?.click();
  };

  const handleFileChangeLicense = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const acceptedFiles = Array.from(e.target.files);
      const updatedImages = [...selectedImage];
      const updatedUUIDs = [...uploadedFile];
      const selectedFileNames = acceptedFiles.map((file) => file.name);

      setLicenseFile((prevFiles) => [...prevFiles, ...selectedFileNames]);
      acceptedFiles.forEach((file) => {
        const docType = "LICENSE";
        console.log("docType", docType);
        updatedImages.push(docType || "");
      });

      setSelectedImage(updatedImages);

      const dataTransfer = new DataTransfer();
      acceptedFiles.forEach((file) => {
        dataTransfer.items.add(file);
      });

      mutate(dataTransfer.files);
    }
  };

  const handleFileChangeEmiratesId = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (e.target.files) {
      const acceptedFiles = Array.from(e.target.files);
      const updatedImages = [...selectedImage];
      const updatedUUIDs = [...uploadedFile];
      const selectedFileNames = acceptedFiles.map((file) => file.name);

      setEmiratesIdFile((prevFiles) => [...prevFiles, ...selectedFileNames]);

      acceptedFiles.forEach((file) => {
        const docType = "EMIRITIES";
        console.log("docType", docType);
        updatedImages.push(docType || "");
      });

      setSelectedImage(updatedImages);

      const dataTransfer = new DataTransfer();
      acceptedFiles.forEach((file) => {
        dataTransfer.items.add(file);
      });

      mutate(dataTransfer.files);
    }
  };

  const handleDeleteImageLicense = (index: number) => {
    setLicenseFile((prevFiles) => {
      const updatedFiles = [...prevFiles];
      updatedFiles.splice(index, 1);
      // Remove corresponding docType if exists
      setSelectedImage((prevImages) => {
        const updatedImages = [...prevImages];
        if (index < prevImages.length) {
          updatedImages.splice(index, 1);
        }
        return updatedImages;
      });
      // Remove corresponding UUID if exists
      setUploadedFile((prevUUIDs) => {
        const updatedUUIDs = [...prevUUIDs];
        if (index < prevUUIDs.length) {
          updatedUUIDs.splice(index, 1);
        }
        return updatedUUIDs;
      });
      return updatedFiles;
    });
  };

  const handleDeleteImageEmiratesId = (index: number) => {
    setEmiratesIdFile((prevFiles) => {
      const updatedFiles = [...prevFiles];
      updatedFiles.splice(index, 1);
      setSelectedImage((prevImages) => {
        const updatedImages = [...prevImages];
        if (index < prevImages.length) {
          updatedImages.splice(index, 1);
        }
        return updatedImages;
      });
      setUploadedFile((prevUUIDs) => {
        const updatedUUIDs = [...prevUUIDs];
        if (index < prevUUIDs.length) {
          updatedUUIDs.splice(index, 1);
        }
        return updatedUUIDs;
      });
      return updatedFiles;
    });
  };

  const onHandleSubmitLicense = () => {
    const formattedExpiryDate = moment(expiryDate).format("YYYY-MM-DD") || "";

    let { residentsLicenseNumber: licence_number } = getValues();

    const imagesData = selectedImage.map((docType, index) => ({
      docType: docType,
      docId: uploadedFile[index],
    }));

    const userData: DocumentDetails = {
      licence_number,
      licence_expiry_date: formattedExpiryDate,
      last_level: 999,
      customerDocs: imagesData,
    };

    console.log("Expiry Date:", expiryDate);

    // Additional functionality from handleSubmit
    const formData = getValues();
    console.log("Data Check", formData);
    const insuranceExpiryDate = { insuranceDate };
    const deliveredBy =
      selectedOptions === "Yes" ? "CUSTOMER" : "KIRKOS_EXECUTIVE";

    navigate("/Customer/InsuranceHolderVehicleInspection", {
      state: {
        formData,
        insuranceExpiryDate,
        deliveredBy,
        selectedCompany,
        selectedGarage,
        selectedGarageId,
      },
    });

    console.log("insuranceExpiryDate", insuranceExpiryDate);
    console.log("Test1", selectedGarage);

    handleRegistration.mutate(userData);
  };

  const handleRegistration = useMutation({
    mutationFn: (userData: DocumentDetails) =>
      axiosInstance.put(API_URL.SIGNUP_MERCHANT, userData),
    onSuccess: (successResponse) => {
      console.log("successResponse", successResponse);
      if (successResponse.status && successResponse?.data?.message) {
      }
    },
    onError: (error: any) => {
      if (error?.data?.message) {
      }
    },
  });

  const {
    data: customerData,

    isError,
    error,
  } = useQuery<CustomerDetails, Error>({
    queryKey: ["details"],
    queryFn: async () => {
      const response = await axiosInstance.get(API_URL.CUSTOMER_DETAILS);
      console.log(response, "response");
      return response.data;
    },
  });

  useEffect(() => {
    if (customerData) {
      setValue(
        "insuranceCompany",
        customerData?.merchant_signup?.insurance_company_name
      );
      setIsCompanyPrefilled(true);
      setValue(
        "insurancePolicy",
        customerData?.merchant_signup?.insurance_policy
      );
      setValue(
        "insuranceClaim",
        customerData?.merchant_signup?.insurance_claim_number
      );
      // setInsuranceDate(customerData?.merchant_signup?.insurance_expiry_date);
    }
  }, [customerData, setValue]);

  useEffect(() => {
    if (selectedCompany) {
      setValue("insurancePolicy", "");
      setValue("insuranceClaim", "");
    }
  }, [selectedCompany, setValue]);

  //---------------------------------------------------------------------------------------------------
  return (
    <div className="col-md-12">
      <Form onSubmit={onHandleSubmitLicense} control={control}>
        <div className="row">
          <div className="col-md-7 page-max-w  mb-2">
            <div className="base-content-booking base-booking-content-dash custom-Booking-base-dashboard ">
              <div className="p-2">
                <div className="row pt-3 text-left basebottom-div mb-3 ">
                  <div className="col-12 d-flex ">
                    <img
                      src={vector_vv}
                      alt="file_browse_icon"
                      className="img-fluid header-icon"
                    />
                    <span className="col-md-11 page-head-add ps-2">
                      Insurance Details
                    </span>
                  </div>
                </div>

                <div className="row d-flex">
                  <div className="col-md-12  text-left page-left">
                    <div className="row">
                      <div className="col-lg-4 pt-2 dashboard-poppins-font-style relative-div">
                        <label className="form-label dashboard-poppins-font-style">
                          Insurance company
                        </label>

                        <CustomInput
                          inputName="insuranceCompany"
                          inputType="text"
                          className="merchant-input-Style-insurance"
                          placeholder="Search for company..."
                          editable={true}
                          onInputChange={handleInputChange}
                          control={control}
                        />
                        {suggestions.length > 0 && (
                          <div className="absolute-div">
                            <ul>
                              {suggestions.map(
                                (company: { id: number; company: string }) => {
                                  console.log("Company Name:", company.company);
                                  return (
                                    <li
                                      key={company.id}
                                      onClick={() => {
                                        setValue(
                                          "insuranceCompany",
                                          company.company
                                        );
                                        setSelectedCompany(company.company);

                                        console.log(
                                          "companyyyy",
                                          selectedCompany
                                        );

                                        setInputValue("");
                                      }}
                                    >
                                      {company.company}
                                    </li>
                                  );
                                }
                              )}
                            </ul>
                          </div>
                        )}
                      </div>

                      <div className="col-lg-4 mt-2">
                        <label className="form-label dashboard-poppins-font-style">
                          Insurance Policy #{" "}
                          <span className="merchantStar">*</span>
                        </label>
                        <CustomInput
                          inputName="insurancePolicy"
                          inputType="text"
                          className="merchant-input-Style-line"
                          placeholder="Eg: 5358689"
                          editable={true}
                          onInputChange={(value: string) => {}}
                          control={control}
                          rules={{ required: true }}
                        />
                      </div>
                      <div className="col-lg-4 mt-2 ">
                        <label className="form-label dashboard-poppins-font-style">
                          Insurance Claim #
                          <span className="merchantStar"> *</span>
                        </label>
                        <CustomInput
                          inputName="insuranceClaim"
                          inputType="text"
                          className="merchant-input-Style-line"
                          placeholder="claimj5464"
                          editable={true}
                          onInputChange={(value: string) => {}}
                          control={control}
                          rules={{ required: true }}
                        />
                      </div>
                      <div className="col-lg-4 mt-4">
                        <label className="form-label dashboard-poppins-font-style">
                          Insurance Expiry Date
                          <span className="merchantStar">*</span>
                        </label>
                        <InsuranceDateComponent
                          pickerType="date"
                          placeholder="Eg:05/2029"
                          value={insuranceDate}
                          onChange={handleInsuranceDateChange}
                        />
                      </div>
                      <div className="col-lg-4 mt-4 align-content-end">
                        <label className="form-label dashboard-poppins-font-style">
                          Plate Number
                        </label>
                        <CustomInput
                          inputName="plateNumber"
                          inputType="text"
                          className="merchant-input-Style-line"
                          placeholder=""
                          editable={true}
                          onInputChange={(value: string) => {}}
                          control={control}
                          //rules={{ required: true }}
                          rules={AuthRules.plate_number}
                        />
                      </div>
                      <div className="col-lg-4 mt-4">
                        <label className="form-label dashboard-poppins-font-style">
                          Chassis Number
                        </label>
                        <CustomInput
                          inputName="chassisNumber"
                          inputType="text"
                          className="merchant-input-Style-line"
                          placeholder="888j5464"
                          editable={true}
                          onInputChange={(value: string) => {}}
                          control={control}
                          //rules={{ required: true }}
                        />
                      </div>
                      <div className="col-lg-4 pt-3 dashboard-poppins-font-style relative-div">
                        <label className="form-label dashboard-poppins-font-style">
                          Garage
                        </label>

                        <CustomInput
                          inputName="garageCompany"
                          inputType="text"
                          className="merchant-input-Style-insurance"
                          placeholder="Search for garage..."
                          editable={true}
                          onInputChange={handleGarageInputChange}
                          control={control}
                        />
                        {augmentedGarageSuggestions.length > 0 && (
                          <div className="absolute-div">
                            <ul>
                              {augmentedGarageSuggestions.map(
                                (garage: {
                                  customer_id: string;
                                  shop_name: string;
                                }) => {
                                  console.log(
                                    "GARAGE Company Name:",
                                    garage.shop_name
                                  );
                                  return (
                                    <li
                                      key={garage.customer_id}
                                      onClick={() => {
                                        setValue(
                                          "garageCompany",
                                          garage.shop_name
                                        );
                                        setSelectedGarage(garage.shop_name);
                                        console.log(
                                          "companyyyy",
                                          selectedGarage
                                        );
                                        setSelectedGarageId(garage.customer_id);
                                        console.log(
                                          "garage idd",
                                          selectedGarageId
                                        );

                                        setInputGarargeValue("");
                                        setShowOtherGarageInput(
                                          garage.shop_name === "Other Garages"
                                        );
                                      }}
                                    >
                                      {garage.shop_name}
                                    </li>
                                  );
                                }
                              )}
                            </ul>
                          </div>
                        )}
                      </div>
                      {showOtherGarageInput && (
                        <div className="col-md-4 mt-2">
                          <label className="form-label dashboard-poppins-font-style">
                            Garage Name <span className="merchantStar">*</span>
                          </label>
                          <CustomInput
                            inputName="otherGarage"
                            inputType="text"
                            className="merchant-input-Style-line"
                            placeholder="garge "
                            editable={true}
                            // onInputChange={(value: string) => {}}
                            control={control}
                            //rules={{ required: true }}
                            onInputChange={(value: string) =>
                              setOtherGarageValue(value)
                            }
                            //value={otherGarageValue}
                          />
                        </div>
                      )}
                    </div>

                    {/* ------------------------------  License added start------------------------------------- */}
                    {!Object.keys(customerData?.customerDocs || {}).length &&
                      customerData && (
                        <>
                          <div className="row pt-4">
                            <div className="col-11 d-flex">
                              <img
                                src={car_key}
                                alt="file_browse_icon"
                                className="img-fluid header-icon"
                              />

                              <span className="col-md-6 ms-2 page-head-add">
                                License Details / Documents *
                              </span>
                            </div>
                          </div>
                          <div className="row">
                            <>
                              <div className="col-md-6 pt-3  ">
                                <label className="form-label">
                                  License Number
                                  <span className="merchantStar">*</span>
                                </label>
                                <CustomInput
                                  inputName="residentsLicenseNumber"
                                  inputType="residentsLicenseNumber"
                                  className="ins_holder_sec_input_style pt-1"
                                  placeholder="Eg: 995721"
                                  editable={true}
                                  onInputChange={(value: string) => {}}
                                  control={control}
                                  rules={rules.AuthRules.license_number}
                                />
                              </div>
                              <div className="col-md-6 pt-3 ">
                                <div className=" col-md-12 res_license_num">
                                  <label className="form-label">
                                    Expiry Date
                                    <span className="merchantStar">*</span>
                                  </label>
                                </div>
                                <div className="col-md-12 expiry-date-field  date-line date-line-noninsurence">
                                  <DatePicker
                                    id="datetimepicker"
                                    selected={
                                      expiryDate ? new Date(expiryDate) : null
                                    }
                                    onChange={handleExpiryDateChange}
                                    dateFormat="EEE, MMM d"
                                    customInput={
                                      <input
                                        readOnly
                                        className="input-date pt-1 ms-1"
                                      />
                                    }
                                    popperClassName="hide-calendar"
                                    showPopperArrow={true}
                                    autoComplete="off"
                                    minDate={
                                      new Date(
                                        new Date().setFullYear(
                                          new Date().getFullYear() + 1
                                        )
                                      )
                                    }
                                  />
                                </div>
                              </div>
                              {expiryError && (
                                <div className="text-danger">
                                  {" "}
                                  {expiryError}
                                </div>
                              )}
                              <div className="col-md-6 pt-3 EnterBox-container ">
                                <div className=" col-md-12 res_license_num">
                                  Copy of your License
                                  <span className="merchantStar">*</span>
                                </div>
                                <div className="col-md-12">
                                  <button
                                    className="btn-custom-upload"
                                    // onClick={() => openFileInputLicense()}
                                    onClick={(e) => openFileInputLicense(e)}
                                  >
                                    Browse File
                                  </button>
                                  <div className="mt-3">
                                    {licenseFile.map((fileName, index) => (
                                      <div
                                        key={index}
                                        className="text-light iconxs"
                                      >
                                        {fileName}
                                        <span
                                          className="text-danger cursor-pointer ms-2"
                                          onClick={() =>
                                            handleDeleteImageLicense(index)
                                          }
                                        >
                                          <Trash />
                                        </span>
                                      </div>
                                    ))}
                                  </div>

                                  <input
                                    type="file"
                                    ref={fileInputRefLicense}
                                    style={{ display: "none" }}
                                    onChange={handleFileChangeLicense}
                                    multiple
                                  />
                                </div>
                              </div>

                              <div className="col-md-6 pt-3 EnterBox-container ">
                                <div className=" col-md-12 res_license_num">
                                  Copy of your Emirates ID
                                  <span className="merchantStar">*</span>
                                </div>
                                <div className="col-md-12 ">
                                  <button
                                    className="btn-custom-upload"
                                    ///onClick={openFileInputEmiratesId}
                                    onClick={(e) => openFileInputEmiratesId(e)}
                                  >
                                    Browse File
                                  </button>

                                  <div className="mt-3">
                                    {emiratesIdFile.map((fileName, index) => (
                                      <div
                                        key={index}
                                        className="text-light iconxs"
                                      >
                                        {fileName}
                                        <span
                                          className="text-danger cursor-pointer ms-2"
                                          onClick={() =>
                                            handleDeleteImageEmiratesId(index)
                                          }
                                        >
                                          <Trash />
                                        </span>
                                      </div>
                                    ))}
                                  </div>

                                  <input
                                    type="file"
                                    ref={fileInputRefEmiratesId}
                                    style={{ display: "none" }}
                                    onChange={handleFileChangeEmiratesId}
                                    multiple
                                  />
                                </div>
                              </div>
                            </>
                          </div>
                        </>
                      )}
                    {/* ------------------------------  License added end------------------------------------- */}

                    {/* -------------------------------------------------------------------- */}
                    <div className="row">
                      <div className="d-flex text-start pt-4">
                        <img
                          src={vehicle_inspection_car}
                          alt="Logo"
                          className="img-fluid header-icon  ml-2"
                        />
                        <div className="page-head-add ps-2 pb-2">
                          Pick up from location or collect
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="row pt-3">
                          <div className="col-md-6">
                            <div className="d-flex">
                              <label className="radio_button_text">
                                Pick up by customer
                              </label>
                              <div className="ps-2">
                                <CustomRadio
                                  onChange={() => handleRadioChange("Yes")}
                                  checked={selectedOptions === "Yes"}
                                  label=""
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="d-flex">
                              <label className="radio_button_text">
                                Deliver by kirkos executive
                              </label>
                              <div className="ps-2">
                                <CustomRadio
                                  onChange={() => handleRadioChange("No")}
                                  checked={selectedOptions === "No"}
                                  label=""
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* ------------------------------------------------ */}
                  </div>
                </div>
              </div>

              <div className="col-md-12 text-end">
                <button
                  className="btn all_button_style text-black"
                  disabled={
                    !insuranceDate ||
                    (!selectedCompany && !isCompanyPrefilled) ||
                    !selectedOptions ||
                    (selectedGarage.includes("Other Garages") &&
                      !otherGarageValue) ||
                    !(
                      (customerData &&
                        Object.keys(customerData.customerDocs || {}).length >
                          0) ||
                      (emiratesIdFile.length > 0 &&
                        licenseFile.length > 0 &&
                        expiryDate)
                    )
                  }
                  type="submit"
                >
                  <div className="mx-2">CONFIRM & CONTINUE</div>
                </button>

                {/* <button
                  className=" btn all_button_style text-black"
                  disabled={
                    // !isValid ||
                    !insuranceDate ||
                    (!selectedCompany && !isCompanyPrefilled) ||
                    !selectedOptions ||
                    (selectedGarage.includes("Other Garages") &&
                      !otherGarageValue)
                  }
                  type="submit"
                >
                  <div className="mx-2">CONFIRM & CONTINUE</div>
                </button> */}
              </div>
            </div>
          </div>
          <div className="col-md-5">
            <RentalSummaryCarousel />
          </div>
        </div>
      </Form>
      {/* <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
      /> */}
    </div>
  );
}

export default BookingProcess;
