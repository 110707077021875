// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.left-textx {
  text-align: left;
  color: var(--font-color-primary);
  font-size: 35px;
  font-family: Poppins, sans-serif;
  line-height: 35px;
  /* letter-spacing: 0.9px; */
  margin-top: 3%;
  font-weight: 400;
}

.logo-mechant-left {
  max-width: 250px;
}

.left-subtextx {
  color: var(--font-color-primary);
  font-size: 16px;
  text-align: left;
  line-height: normal;
  letter-spacing: 0.7px;
  font-family: Poppins, sans-serif;
}

.left-redcarStylex {
  min-width: 10%;
  height: 40px;
  position: relative;
  padding-right: 68%;
  right: 1rem;
}

.left-whitecarStylex {
  min-width: 10%;
  height: auto;
  width: 50%;
  margin-left: 20%;
  margin-bottom: 0px;
}

.left-yellowcarStylex {
  padding-left: 40%;
  height: auto;
  min-width: 30%;
  width: 85%;
  position: relative;
  left: 2rem;
}

/* .left-yellowcarStylex-img:hover{
    margin-left: 1000px;
    transition: ease-in 5.5s;
  } */
`, "",{"version":3,"sources":["webpack://./src/components/MerchantOnboardLeftSide/MerchantOnboardLeftSide.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,gCAAgC;EAChC,eAAe;EACf,gCAAgC;EAChC,iBAAiB;EACjB,2BAA2B;EAC3B,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gCAAgC;EAChC,eAAe;EACf,gBAAgB;EAChB,mBAAmB;EACnB,qBAAqB;EACrB,gCAAgC;AAClC;;AAEA;EACE,cAAc;EACd,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,cAAc;EACd,YAAY;EACZ,UAAU;EACV,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;EACjB,YAAY;EACZ,cAAc;EACd,UAAU;EACV,kBAAkB;EAClB,UAAU;AACZ;;AAEA;;;KAGK","sourcesContent":[".left-textx {\n  text-align: left;\n  color: var(--font-color-primary);\n  font-size: 35px;\n  font-family: Poppins, sans-serif;\n  line-height: 35px;\n  /* letter-spacing: 0.9px; */\n  margin-top: 3%;\n  font-weight: 400;\n}\n\n.logo-mechant-left {\n  max-width: 250px;\n}\n\n.left-subtextx {\n  color: var(--font-color-primary);\n  font-size: 16px;\n  text-align: left;\n  line-height: normal;\n  letter-spacing: 0.7px;\n  font-family: Poppins, sans-serif;\n}\n\n.left-redcarStylex {\n  min-width: 10%;\n  height: 40px;\n  position: relative;\n  padding-right: 68%;\n  right: 1rem;\n}\n\n.left-whitecarStylex {\n  min-width: 10%;\n  height: auto;\n  width: 50%;\n  margin-left: 20%;\n  margin-bottom: 0px;\n}\n\n.left-yellowcarStylex {\n  padding-left: 40%;\n  height: auto;\n  min-width: 30%;\n  width: 85%;\n  position: relative;\n  left: 2rem;\n}\n\n/* .left-yellowcarStylex-img:hover{\n    margin-left: 1000px;\n    transition: ease-in 5.5s;\n  } */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
