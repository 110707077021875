import { useState } from "react";
import {
  vehicle_inspection_img,
  star,
  car_key,
  baby_seat,
  steering_wheel,
  mdi_car_insurance,
  wind_shield_protection_icon,
} from "../../../assets/images";
import "react-datepicker/dist/react-datepicker.css";
import CustomInput from "../../CustomInput";
import DateTimeComponent from "../../dateAndTime";
import { useForm } from "react-hook-form";
import "./styles.css";
import RentalSummaryCarousel from "../RentalSummaryCarousel";
import { useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import CustomRadio from "../../customRadio";
import axiosInstance from "../../../services/axios.instance";
import { API_URL } from "../../../services/apiConfig";
import { DriverAmount, ExtraAmount } from "../../../types";

interface LocationData {
  latitude: string;
  longitude: string;
}

const InsuranceHolderVehicleInspection = () => {
  const location = useLocation();
  const {
    formData,
    insuranceExpiryDate,
    deliveredBy,
    selectedCompany,
    selectedGarage,
    selectedGarageId,
  } = location.state;

  const {
    control,
    formState: { isValid },
    setValue,
  } = useForm({ mode: "onChange" });

  const [searchQuery, setSearchQuery] = useState<string>("");

  const [addressSuggestions, setAddressSuggestions] = useState<string[]>([]);
  const [selectedLocation, setSelectedLocation] = useState<LocationData>({
    latitude: "0",
    longitude: "0",
  });
  const [selectedSuggestion, setSelectedSuggestion] = useState<string>("");
  const handleInputChange = (value: string) => {
    setSearchQuery(value);
  };
  const handleRecollectionInputChange = (value: string) => {
    setRecollectionSearchQuery(value);
  };

  const [recollectionSearchQuery, setRecollectionSearchQuery] =
    useState<string>("");
  const [recollectionAddressSuggestions, setRecollectionAddressSuggestions] =
    useState<string[]>([]);
  const [selectedRecollectionLocation, setSelectedRecollectionLocation] =
    useState<LocationData>({
      latitude: "0",
      longitude: "0",
    });
  const [selectedRecollectionSuggestion, setSelectedRecollectionSuggestion] =
    useState<string>("");

  const [deliveryDate, setDeliveryDate] = useState<string>("");
  const [deliveryTime, setDeliveryTime] = useState("");
  const [extraBabySeat, setExtraBabySeat] = useState<boolean | null>(null);
  const [additionalDriver, setAdditionalDriver] = useState<boolean | null>(
    null
  );
  const [windShieldProtection, setWindShieldProtection] = useState<
    boolean | null
  >(null);
  const [scdwInsurance, setSCDWInsurance] = useState<boolean | null>(null);
  const [paiInsurance, setPaiInsurance] = useState<boolean | null>(null);

  const fetchLocationName = async (searchQuery: string) => {
    const response = await fetch(
      `https://nominatim-carhire-dev.livelocal.delivery/search.php?q=${searchQuery}&limit=5&format=json&addressdetails=1`
    );
    console.log(response, "wowww");
    const data = await response.json();
    const suggestions = data.map((item: any) => item.display_name);
    setAddressSuggestions(suggestions);
    const locationName = data?.display_name;
    return locationName;
  };

  const fetchRecollectionLocationName = async (
    recollectionSearchQuery: string
  ) => {
    const response = await fetch(
      `https://nominatim-carhire-dev.livelocal.delivery/search.php?q=${recollectionSearchQuery}&limit=5&format=json&addressdetails=1`
    );
    const data = await response.json();
    const suggestions = data.map((item: any) => item.display_name);
    setRecollectionAddressSuggestions(suggestions);
    const locationName = data?.display_name;
    return locationName;
  };

  useQuery<string>({
    queryKey: ["locationName", searchQuery],
    queryFn: () => fetchLocationName(searchQuery),
  });

  useQuery<string>({
    queryKey: ["recollectionLocationName", recollectionSearchQuery],
    queryFn: () => fetchRecollectionLocationName(recollectionSearchQuery),
  });

  const handleSuggestionClick = async (selectedSuggestion: string) => {
    const response = await fetch(
      `https://nominatim-carhire-dev.livelocal.delivery/search.php?q=${selectedSuggestion}&limit=5&format=json&addressdetails=1`
    );
    const data = await response.json();
    if (data.length > 0) {
      const { lat, lon } = data[0];
      setSelectedLocation({
        latitude: parseFloat(lat).toString(),
        longitude: parseFloat(lon).toString(),
      });
    }
    setSelectedSuggestion(selectedSuggestion);
    setValue("DeliveryLocation", selectedSuggestion);
    setAddressSuggestions([]);
  };

  const handleRecollectionSuggestionClick = async (
    selectedRecollectionSuggestion: string
  ) => {
    const response = await fetch(
      `https://nominatim-carhire-dev.livelocal.delivery/search.php?q=${selectedRecollectionSuggestion}&limit=5&format=json&addressdetails=1`
    );
    const data = await response.json();
    if (data.length > 0) {
      const { lat, lon } = data[0];
      setSelectedRecollectionLocation({
        latitude: parseFloat(lat).toString(),
        longitude: parseFloat(lon).toString(),
      });
    }
    setSelectedRecollectionSuggestion(selectedRecollectionSuggestion);
    setValue("RecollectionLocation", selectedRecollectionSuggestion);
    setRecollectionAddressSuggestions([]);
  };

  const handleDeliveryDateChange = (date: string) => {
    setDeliveryDate(date);
    console.log("befffffffffffff", date);
  };

  const handleTimeChange = (newTime: any) => {
    setDeliveryTime(newTime);
  };

  const navigate = useNavigate();
  const handleSubmitInsurenceHolderVehicleinspection = () => {
    const deliveryDetails = {
      deliveryLocation: selectedSuggestion,
      latitude: selectedLocation.latitude,
      longitude: selectedLocation.longitude,
      //deliveryDate: deliveryDate,
      deliveryDate:
        moment(deliveryDate).format("YYYY-MM-DDTkk:mm:ss.sss") + "Z",
      deliveryTime: deliveryTime,
    };
    const recollectionDetails = {
      recollectionLocation: selectedRecollectionSuggestion,
      latitude: selectedRecollectionLocation.latitude,
      longitude: selectedRecollectionLocation.longitude,
    };
    navigate("/Customer/FinalReport", {
      state: {
        deliveryDetails,
        recollectionDetails,
        formData,
        insuranceExpiryDate,
        deliveryDate,
        deliveryTime,
        deliveredBy,
        selectedCompany,
        selectedGarage,
        selectedGarageId,
        extraBabySeat,
        additionalDriver,
        windShieldProtection,
        scdwInsurance,
        childSeatDaily,
        PAIDaily,
        SDWDaily,
        WTPDaily,
        DriverDaily,
        paiInsurance,
      },
    });
  };

  const isFormFilled = () => {
    return (
      selectedSuggestion !== "" &&
      selectedRecollectionSuggestion !== "" &&
      deliveryDate !== "" &&
      deliveryTime !== ""
    );
  };

  const handlePaiChange = () => {
    setPaiInsurance((prevValue) => !prevValue);
  };

  const { data: extraAmountData } = useQuery<ExtraAmount[], Error>({
    queryKey: ["extraAmount"],
    queryFn: async () => {
      const response = await axiosInstance.get(API_URL.EXTRA_AMOUNT);
      return response.data;
    },
  });

  const { data: driverAmountData } = useQuery<DriverAmount[], Error>({
    queryKey: ["driverAmount"],
    queryFn: async () => {
      const response = await axiosInstance.get(API_URL.ADDITIONAL_DRIVER_FEE);
      return response.data;
    },
  });
  const dailyRates = extraAmountData?.filter((item) => item.usage === "DAILY");
  const childSeatDaily = extraAmountData?.find(
    (item) => item.key === "CHILD_SEAT" && item.usage === "DAILY"
  );
  const PAIDaily = extraAmountData?.find(
    (item) => item.key === "PAI" && item.usage === "DAILY"
  );

  const WTPDaily = extraAmountData?.find(
    (item) => item.key === "WTP" && item.usage === "DAILY"
  );

  const SDWDaily = extraAmountData?.find(
    (item) => item.key === "SDW" && item.usage === "DAILY"
  );

  const DriverDaily = driverAmountData?.find(
    (item) => item.delivery_type === "INSURANCE"
  );

  // useEffect(() => {
  //   extraAmount.mutate();
  // }, []);

  return (
    <div className="col-md-12">
      <div className="row">
        <div className="col-md-7">
          <div className="card main-vi">
            <div className="card-body">
              {/* --------------------------- */}

              <div className="row">
                <div className="row mt-1">
                  <div className="col-12  ps-3 page-head-add text-start">
                    Additional Options
                  </div>
                </div>

                <div className="col-md-6 pt-3 text-start">
                  <table style={{ width: "75%" }}>
                    <tr>
                      <td rowSpan={2}>
                        <img
                          src={baby_seat}
                          alt="file_browse_icon"
                          className="img-fluid header-icon"
                        />
                      </td>
                      <td
                        style={{
                          color: "#A1A1A1",
                          fontSize: "10px",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        <span className="non_insur_per_day_aed">
                          {" "}
                          AED {childSeatDaily?.insuranceRate}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td className="page-head-sub"> Extra baby Seat</td>
                    </tr>
                  </table>
                  <table style={{ width: "50%" }} className="mt-2 ms-1">
                    <tr>
                      <td>
                        <div className="d-flex">
                          <label className="radio-label-noninsurence">
                            Yes
                          </label>
                          <div className="ps-2">
                            <CustomRadio
                              label=""
                              checked={extraBabySeat === true}
                              onChange={() => setExtraBabySeat(true)}
                            />
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex">
                          <label className="radio-label-noninsurence">No</label>
                          <div className="ps-2">
                            <CustomRadio
                              label=""
                              checked={extraBabySeat === false}
                              onChange={() => setExtraBabySeat(false)}
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
                <div className="col-md-6 text-start pt-3">
                  <table style={{ width: "75%" }}>
                    <tr>
                      <td rowSpan={2}>
                        <img
                          src={steering_wheel}
                          alt="file_browse_icon"
                          className="img-fluid header-icon"
                        />
                      </td>
                      <td
                        style={{
                          color: "#A1A1A1",
                          fontSize: "10px",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        Per day{" "}
                        <span className="non_insur_per_day_aed">
                          AED {DriverDaily?.amount}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td className="page-head-sub">Additional Driver</td>
                    </tr>
                  </table>
                  <table style={{ width: "50%" }} className="mt-2 ms-1">
                    <tr>
                      <td>
                        <div className="d-flex">
                          <label className="radio-label-noninsurence">
                            Yes
                          </label>
                          <div className="ps-2">
                            <CustomRadio
                              label=""
                              checked={additionalDriver === true}
                              onChange={() => setAdditionalDriver(true)}
                            />
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex">
                          <label className="radio-label-noninsurence">No</label>
                          <div className="ps-2">
                            <CustomRadio
                              label=""
                              checked={additionalDriver === false}
                              onChange={() => setAdditionalDriver(false)}
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
                <div className="col-md-6 pt-3 text-start">
                  <table style={{ width: "100%" }}>
                    <tr>
                      <td rowSpan={2}>
                        <img
                          src={wind_shield_protection_icon}
                          alt="file_browse_icon"
                          className="img-fluid header-icon"
                        />
                      </td>
                      <td
                        style={{
                          color: "#A1A1A1",
                          fontSize: "10px",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        Per day{" "}
                        <span className="non_insur_per_day_aed">
                          AED {WTPDaily?.insuranceRate}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td className="page-head-sub">WindShield Protection</td>
                    </tr>
                  </table>
                  <table style={{ width: "50%" }} className="mt-2 ms-1">
                    <tr>
                      <td>
                        <div className="d-flex">
                          <label className="radio-label-noninsurence">
                            Yes
                          </label>
                          <div className="ps-2">
                            <CustomRadio
                              label=""
                              checked={windShieldProtection === true}
                              onChange={() => setWindShieldProtection(true)}
                            />
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex">
                          <label className="radio-label-noninsurence">No</label>
                          <div className="ps-2">
                            <CustomRadio
                              label=""
                              checked={windShieldProtection === false}
                              onChange={() => setWindShieldProtection(false)}
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
                <div className="col-md-6 text-start pt-3">
                  <table style={{ width: "75%" }}>
                    <tr>
                      <td rowSpan={2}>
                        <img
                          src={mdi_car_insurance}
                          alt="file_browse_icon"
                          className="img-fluid header-icon"
                        />
                      </td>
                      <td
                        style={{
                          color: "#A1A1A1",
                          fontSize: "10px",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        Per day{" "}
                        <span className="non_insur_per_day_aed">
                          AED {SDWDaily?.insuranceRate}
                        </span>
                      </td>
                    </tr>

                    <tr>
                      <td className="page-head-sub">SCDW Insurance</td>
                    </tr>
                  </table>
                  <table style={{ width: "50%" }} className="mt-2 ms-3">
                    <tr>
                      <td>
                        <div className="d-flex">
                          <label className="radio-label-noninsurence">
                            Yes
                          </label>
                          <div className="ps-2">
                            <CustomRadio
                              label=""
                              checked={scdwInsurance === true}
                              onChange={() => setSCDWInsurance(true)}
                            />
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex">
                          <label className="radio-label-noninsurence">No</label>
                          <div className="ps-2">
                            <CustomRadio
                              label=""
                              checked={scdwInsurance === false}
                              onChange={() => setSCDWInsurance(false)}
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 pt-3 text-start">
                  <table style={{ width: "75%" }}>
                    <tr>
                      <td className="page-head-sub" rowSpan={2}>
                        <img
                          src={mdi_car_insurance}
                          alt="file_browse_icon"
                          className="img-fluid header-icon "
                        />
                      </td>
                      <td
                        style={{
                          color: "#A1A1A1",
                          fontSize: "10px",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        <div className="non_insur_per_day_aed">
                          {" "}
                          AED {PAIDaily?.insuranceRate}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="page-head-sub">PAI Insurance</td>
                    </tr>
                  </table>
                  <table style={{ width: "50%" }} className="mt-2 ms-3">
                    <tr>
                      <td>
                        <div className="d-flex">
                          <label className="radio-label-noninsurence">
                            Yes
                          </label>
                          <div className="ps-2">
                            <CustomRadio
                              label=""
                              checked={paiInsurance === true}
                              onChange={() => setPaiInsurance(true)}
                            />
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex">
                          <label className="radio-label-noninsurence">No</label>
                          <div className="ps-2">
                            <CustomRadio
                              label=""
                              checked={paiInsurance === false}
                              onChange={() => setPaiInsurance(false)}
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                  </table>
                  {/* <table style={{ width: "100%" }} className="mt-2">
                    <tr>
                      <td>
                        <div className="col-md-12 d-flex">
                          <p className="mb-0 ms-1 ps-0 selection-container-custom-license">
                            PAI Insurance
                          </p>
                          <td>
                            <div className="col-md-1 pe-0 me-0 ms-2">
                              <CustomCheckBox
                                isChecked={paiInsurance}
                                onChange={handlePaiChange}
                              />
                            </div>
                          </td>
                        </div>
                      </td>
                    </tr>
                  </table> */}
                </div>
              </div>

              <div className="row mt-5">
                <div className="col-12 d-flex">
                  <img
                    src={car_key}
                    alt="file_browse_icon"
                    className="img-fluid header-icon"
                  />
                  <span className="col-md-11 ps-1 page-head-add text-start">
                    Confirm Car Delivery Location, Date, Time
                  </span>
                </div>
              </div>
              <div className="row pt-4 ps-4">
                <div className="col-lg-4 pt-3">
                  <div className="div-border-loc relative-div align-content-end">
                    <label className="label_insurance">
                      Delivery Location <span className="merchantStar">*</span>
                    </label>

                    <CustomInput
                      inputName="DeliveryLocation"
                      inputType="text"
                      className="custom-bottom-line-vheins"
                      placeholder="E-20, Dera, Dubai"
                      editable={true}
                      onInputChange={(value: string) =>
                        handleInputChange(value)
                      }
                      control={control}
                    />
                    {addressSuggestions.length > 0 && (
                      <div className="absolute-div">
                        <ul className="text-start" style={{ color: "white" }}>
                          {addressSuggestions.map((suggestion, index) => (
                            <li
                              key={index}
                              onClick={() => handleSuggestionClick(suggestion)}
                            >
                              {suggestion}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
                {/* ============================================================== */}
                <div className="col-lg-4 pt-3">
                  <div className="div-border-loc relative-div align-content-end">
                    <label className="label_insurance">
                      Recollection Location{" "}
                      <span className="merchantStar">*</span>
                    </label>
                    <CustomInput
                      inputName="RecollectionLocation"
                      inputType="text"
                      className="custom-bottom-line-vheins"
                      placeholder="E-20, Dera, Dubai"
                      editable={true}
                      onInputChange={(value: string) =>
                        handleRecollectionInputChange(value)
                      }
                      control={control}
                    />
                    {recollectionAddressSuggestions.length > 0 && (
                      <div className="absolute-div">
                        <ul className="text-start" style={{ color: "white" }}>
                          {recollectionAddressSuggestions.map(
                            (suggestion, index) => (
                              <li
                                key={index}
                                onClick={() =>
                                  handleRecollectionSuggestionClick(suggestion)
                                }
                              >
                                {suggestion}
                              </li>
                            )
                          )}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>

                {/* ============================================================ */}
                <div className="col-lg-4 pt-3">
                  <DateTimeComponent
                    pickerType="date"
                    placeholder="Delivery Date"
                    label="Delivery Date"
                    value={deliveryDate}
                    onChange={handleDeliveryDateChange}
                    className="date-field-with-star"
                  />
                </div>

                <div className="col-lg-4 pt-3 text-start align-content-end">
                  <label className="label_insurance">
                    Delivery Slot/Time <span className="merchantStar">*</span>
                  </label>

                  <select
                    className="select-delivery-slot pt-1"
                    onChange={(e) => handleTimeChange(e.target.value)}
                  >
                    <option value="">Select</option>
                    <option value="Morning">Morning</option>
                    <option value="Afternoon">Afternoon</option>
                    <option value="Evening">Evening</option>
                  </select>
                </div>
              </div>

              <div className="row mt-5">
                <div className="col-12 d-flex">
                  <img
                    src={vehicle_inspection_img}
                    alt="file_browse_icon"
                    className="img-fluid header-icon"
                  />
                  <span className="col-md-11 ps-1 page-head-add text-start">
                    Security Deposit
                  </span>
                </div>
              </div>
              <div className="row ps-4">
                <div className="col-md-12 pt-2">
                  <div className="row ">
                    <div
                      className="col-md-12 text-start"
                      style={{ textAlign: "left" }}
                    >
                      <img src={star} alt="Logo" className="" />
                      <span className=" descripton_vi">
                        &nbsp;Security deposit is required at the time of
                        vehicle delivery
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-12 d-flex">
                  <img
                    src={vehicle_inspection_img}
                    alt="file_browse_icon"
                    className="img-fluid header-icon"
                  />
                  <span className="col-md-11 ps-1 page-head-add text-start">
                    Informations
                  </span>
                </div>
              </div>
              <div className="row ps-4">
                <div className="col-md-12 pt-2">
                  <div className="row ">
                    {/* <div
                      className="col-md-12 text-start"
                      style={{ textAlign: "left" }}
                    >
                      <img src={star} alt="Logo" className="" />
                      <span className=" descripton_vi">
                        &nbsp; Vehicle provided will be based on your insurance
                        approval
                      </span>
                    </div> */}
                    <div
                      className="col-md-12 text-start"
                      style={{ textAlign: "left" }}
                    >
                      <img src={star} alt="Logo" className="" />
                      <span className=" descripton_vi">
                        &nbsp; Please pay the additional options at the time of
                        car delivery.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 text-end">
                <button
                  type="button"
                  className=" btn button-footer-text my-4"
                  onClick={handleSubmitInsurenceHolderVehicleinspection}
                  disabled={!isValid || !isFormFilled()}
                >
                  <span className="mx-2">
                    CONFIRM & GENERATE FINAL RENTAL REPORT
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-5">
          <RentalSummaryCarousel />
        </div>
      </div>
    </div>
  );
};

export default InsuranceHolderVehicleInspection;
