import React from "react";
import "./afl.css";
import { Nav, Navbar } from "react-bootstrap";
import {
  icon_black,
  icon_normal,
  kirkos_logo_white_yellow,
  landing_page_image,
} from "../../assets/images";
import { Link } from "react-router-dom";
import {
  ArrowRightCircle,
  ArrowRightCircleFill,
  CarFront,
  CarFrontFill,
  WrenchAdjustableCircle,
  WrenchAdjustableCircleFill,
} from "react-bootstrap-icons";

const AflLandingPage = () => {
  return (
    <div className="landing-page-main-div">
      <Navbar
        variant="dark"
        bg="transparent"
        expand="lg"
        className="custom-nav-font px-4 mx-4 pt-5"
      >
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Brand href="#home" className="navbar-brand-padding">
          <img
            src={kirkos_logo_white_yellow}
            alt="Kirkos-Logo"
            className="brands-logo"
          />
        </Navbar.Brand>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mx-auto align-items-center welcome_navbar ">
            <Nav.Link
              as={Link}
              to="/merchant/signup"
              className="custom-nav-family new-name text-start"
            >
              BECOME A MERCHANT
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/signUp"
              className="custom-nav-family new-name-two text-start"
            >
              JOIN AS A CUSTOMER
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="#make-car-active"
              className="custom-nav-family new-name-mid text-start"
            >
              MAKE YOUR IDEAL CAR ACTIVE AND EARN
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/GarageOnboard"
              className="custom-nav-family  new-name-two text-start"
            >
              GARAGE
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/signIn"
              className="custom-nav-family new-name-three"
            >
              CUSTOMER LOGIN{" "}
              <img src={icon_black} alt="Kirkos-Logo" className="icon_img" />
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/SignInMerchant"
              className="custom-nav-family new-name-four"
            >
              MERCHANT LOGIN{" "}
              <img src={icon_normal} alt="Kirkos-Logo" className="icon_img" />
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <div className="row landing-page-div">
        <div className="col-sm-7 px-5">
          <div className="landing-page-content-div p-2">
            <img
              src={landing_page_image}
              alt=""
              className="img-fluid landing_page_image"
            />
            <div className="responsive-text">
              <WrenchAdjustableCircle
                size={35}
                color="var(--font-colour-theme-1)"
              />
              {"  "}
              Welcome to Al-Futtaim
            </div>
            {/* <div className="pt-4">GARAGE</div> */}
            <div className="pt-2 pb-3">
              <Link to={"/GarageOnboard/email"} className="pt-1">
                <button className="btn sign-up-btn px-4">
                  <div className="d-flex">
                    <div>SIGN UP &nbsp; </div>
                    <div className="icon-hover">
                      <ArrowRightCircle size={16} />
                    </div>
                  </div>
                </button>
              </Link>
              &nbsp;&nbsp;
              <Link to={"/GarageOnboard"} className="pt-1">
                <button className="btn sign-in-btn px-4">
                  <div className="d-flex">
                    <div>SIGN IN &nbsp; </div>
                    <div className="icon-hover">
                      <ArrowRightCircle size={16} />
                    </div>
                  </div>
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AflLandingPage;
