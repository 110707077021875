import {
  group_icon,
  petrol_pump,
  stars,
  mileage,
} from "../../../assets/images";
import "./styles.css";

interface RentalSummaryProps {
  daysDifference: number;
  totalAmount: number;
  carImage: string;
  carModel: string;
  seat: number;
  fuelType: string;
}
// function RentalSummary() {
const RentalSummary: React.FC<RentalSummaryProps> = ({
  daysDifference,
  totalAmount,
  carImage,
  carModel,
  seat,
  fuelType,
}) => {
  return (
    <div className="col-md-12">
      <div className="card card-custom">
        <div className="card-body">
          <div className=" ">
            <div className="col-md-12 text-style">
              <h5> Rental Summary </h5>
            </div>
            <div
              className="col-md-9 text-colour dashboard-poppins-font-style fontsize3"
              style={{ textAlign: "left" }}
            >
              Price may change if you exceed 1 hour within the agreed time{" "}
            </div>
            <div className="row pt-2">
              <div className="col-md-6 car-style">
                <img
                  /// src={rental_car}
                  src={carImage}
                  alt="file_browse_icon"
                  className=" file_browse_icon"
                />
              </div>
              <div className="col-md-6 text-start ">
                <div className="rent-carmodel" style={{ marginTrim: "none" }}>
                  {carModel}
                </div>
                <img src={stars} alt="img-fluid" className="img-fluid" />
                <div style={{ color: "yellow", textDecoration: "underline" }}>
                  20+ Comments
                </div>
              </div>
            </div>
            <div className="row pt-2">
              <div className="col-md-12 text-start">
                <div className="col-md-12 text-start  pb-2 dashboard-poppins-font-style  car-name-search-bold">
                  Features
                </div>
                <div className="d-flex">
                  <div className="">
                    <img
                      src={group_icon}
                      alt="file_browse_icon"
                      className="img-fluid"
                    />
                    <span className="text-colour ps-1">{seat}</span>
                  </div>
                  <div className="ms-4">
                    <img
                      src={petrol_pump}
                      alt="file_browse_icon"
                      className="img-fluid img-width-icon"
                    />
                    <span className="text-colour dashboard-poppins-font-style">
                      {fuelType}
                    </span>
                  </div>
                </div>
                <div className="col-md-12 pt-1">
                  <img
                    src={mileage}
                    alt="file_browse_icon"
                    className="img-fluid img-width-icon"
                    style={{ alignItems: "left" }}
                  />
                  <span className="text-colour ps-1 dashboard-poppins-font-style">
                    Unlimited Mileage
                  </span>
                </div>
              </div>
            </div>
            <div className="row pt-3">
              <div className="col-md-12">
                <table className="text-start table-width-custom table-rentalsummery table-amount">
                  <tbody>
                    <tr className="fontsize ">
                      <td className="">
                        Subtotal{" "}
                        <span style={{ color: "grey" }}>
                          ({daysDifference}days)
                        </span>
                      </td>
                      <td>AED {totalAmount}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="row pt-3">
              <div className="col-md-12">
                <table className="text-start table-width-custom  table-rentalsummery table-total-amount">
                  <tbody>
                    <tr className="fontsize2">
                      <td className="">Total Rental Price</td>
                      <td>AED {totalAmount}</td>
                    </tr>
                    <tr className="fontsize3">
                      <td colSpan={2}>
                        <span style={{ color: "yellow" }}>* </span>
                        You are not Kirkos Insurance holder so you have to pay
                        the full charges via payment gateway
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RentalSummary;
