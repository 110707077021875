import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { dashboard_search_normal } from "../../../assets/images";
import { useQuery } from "@tanstack/react-query";
import { GarageOrderResponse } from "../../../types";
import { API_URL } from "../../../services/apiConfig";
import axiosInstance from "../../../services/axios.instance";
import { Pagination } from "react-bootstrap";

const GarageInsuranceCalimList = () => {
  const [selectedButton, setSelectedButton] = useState("BOOKED");
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);

  const { data: newOrderData, refetch } = useQuery<
    GarageOrderResponse[],
    Error
  >({
    queryKey: ["garageorders", selectedButton, currentPage],
    queryFn: async () => {
      const response = await axiosInstance.get(
        `${API_URL.GARAGE_ORDER_LISTING}/${selectedButton}?page=${currentPage}&count=10`,
        {
          headers: {
            location: "0,0",
          },
        }
      );
      setTotalPages(response.data.totalPages);
      return response.data.content;
    },
  });

  useEffect(() => {
    refetch();
  }, [selectedButton, currentPage, refetch]);

  const handleTabButton = (select: string) => {
    setSelectedButton(select);
    setCurrentPage(0);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <div className="col-12 pt-4 ">
      <div className="text-start border-table-top-garage scroll-style">
        <div className="d-flex justify-content-end p-2">
          <div className="col-3 widith-table-search">
            <div className="search-bar-bg p-1">
              <form className="d-flex">
                <label htmlFor="searchbox">
                  <img src={dashboard_search_normal} width="30" height="30" />
                </label>
                <input
                  id="searchbox"
                  type="text"
                  className="search-bar-input-box px-1"
                  placeholder="Search"
                />
              </form>
            </div>
          </div>
        </div>
        <div className="button-div-bg">
          <button
            className={`btn button-garage-card ${
              selectedButton === "BOOKED" ? "activeorderbuttontab" : ""
            }`}
            onClick={() => handleTabButton("BOOKED")}
          >
            NEW ORDERS
          </button>
          <button
            className={`btn button-garage-card ${
              selectedButton === "RECEIVED" ? "activeorderbuttontab" : ""
            }`}
            onClick={() => handleTabButton("RECEIVED")}
          >
            RECEIVED
          </button>
          <button
            className={`btn button-garage-card ${
              selectedButton === "WORKING" ? "activeorderbuttontab" : ""
            }`}
            onClick={() => handleTabButton("WORKING")}
          >
            WORKING
          </button>
          <button
            className={`btn button-garage-card ${
              selectedButton === "COMPLETED" ? "activeorderbuttontab" : ""
            }`}
            onClick={() => handleTabButton("COMPLETED")}
          >
            COMPLETED
          </button>
          <button
            className={`btn button-garage-card ${
              selectedButton === "REJECTED" ? "activeorderbuttontab" : ""
            }`}
            onClick={() => handleTabButton("REJECTED")}
          >
            REJECTED
          </button>
        </div>
      </div>
      <div className="col-12 dashboardtablediv">
        <table className="table table-hover dashboardtable">
          <thead>
            <tr>
              <th>#</th>
              <th>Order Number</th>
              <th>Name</th>
              <th>Subsidiary Name</th>
              <th>Service Name</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {newOrderData?.map((order, index) => (
              <tr key={order.orderId}>
                <td>{index + 1 + currentPage * 10}</td> {/* Adjust for page */}
                <td>{order?.orderId}</td>
                <td>{order?.customer_name}</td>
                <td>{order?.order_service_details?.subsidiaryName}</td>
                <td>{order?.order_service_details?.serviceName}</td>
                <td>
                  <Link
                    className="assignButton"
                    to={`/Garage/InsuranceOrderDetails?OrderId=${order.orderId}`}
                  >
                    view
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <Pagination className="pagination-align">
          <Pagination.Prev
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 0}
          />
          {Array.from({ length: totalPages }, (_, index) => (
            <Pagination.Item
              key={index}
              active={index === currentPage}
              onClick={() => handlePageChange(index)}
            >
              {index + 1}
            </Pagination.Item>
          ))}
          <Pagination.Next
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages - 1}
          />
        </Pagination>
      </div>
    </div>
  );
};

export default GarageInsuranceCalimList;
