// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.landing-page-main-div {
  height: 100%;
  min-height: 100vh;
  /* background-color: #fff; */
  background-image: linear-gradient(350deg, #a6ccff 45%, #a6ccff, #eeeeee 45%);
  /* background: url("/src/assets/images/truck.png"); */
  background-size: cover;
  background-repeat: no-repeat;
}

.landing-page-div {
  height: 100%;
  min-height: 80vh;
  align-content: center;
  justify-content: center;
  padding: 5px;
}
.landing-page-content-div {
  min-height: 300px;
  box-shadow: 1px 1px 3px var(--shadow-color-theme-9);
  align-content: center;
  border-radius: 15px;
  background-color: #ffffff;
  position: relative;
  /* background-image: url("/src/assets/images/truck.png"); */
  background-size: cover;
  background-repeat: no-repeat;
}

.sign-in-btn {
  border: 1px solid var(--border-color-theme-18);
  color: var(--font-color-theme-hover-1);
}

.sign-in-btn:hover {
  background-image: linear-gradient(var(--button-background-hover-radial));
  color: var(--font-color-primary);
}

.sign-up-btn {
  border: 1px solid var(--border-color-theme-18);
  color: var(--font-color-theme-hover-1);
}

.sign-up-btn:hover {
  background-image: linear-gradient(var(--button-background-hover-radial));
  color: var(--font-color-primary);
}

.landing_page_image {
  max-height: 200px;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/AflLandingPage/afl.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,iBAAiB;EACjB,4BAA4B;EAC5B,4EAA4E;EAC5E,qDAAqD;EACrD,sBAAsB;EACtB,4BAA4B;AAC9B;;AAEA;EACE,YAAY;EACZ,gBAAgB;EAChB,qBAAqB;EACrB,uBAAuB;EACvB,YAAY;AACd;AACA;EACE,iBAAiB;EACjB,mDAAmD;EACnD,qBAAqB;EACrB,mBAAmB;EACnB,yBAAyB;EACzB,kBAAkB;EAClB,2DAA2D;EAC3D,sBAAsB;EACtB,4BAA4B;AAC9B;;AAEA;EACE,8CAA8C;EAC9C,sCAAsC;AACxC;;AAEA;EACE,wEAAwE;EACxE,gCAAgC;AAClC;;AAEA;EACE,8CAA8C;EAC9C,sCAAsC;AACxC;;AAEA;EACE,wEAAwE;EACxE,gCAAgC;AAClC;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".landing-page-main-div {\n  height: 100%;\n  min-height: 100vh;\n  /* background-color: #fff; */\n  background-image: linear-gradient(350deg, #a6ccff 45%, #a6ccff, #eeeeee 45%);\n  /* background: url(\"/src/assets/images/truck.png\"); */\n  background-size: cover;\n  background-repeat: no-repeat;\n}\n\n.landing-page-div {\n  height: 100%;\n  min-height: 80vh;\n  align-content: center;\n  justify-content: center;\n  padding: 5px;\n}\n.landing-page-content-div {\n  min-height: 300px;\n  box-shadow: 1px 1px 3px var(--shadow-color-theme-9);\n  align-content: center;\n  border-radius: 15px;\n  background-color: #ffffff;\n  position: relative;\n  /* background-image: url(\"/src/assets/images/truck.png\"); */\n  background-size: cover;\n  background-repeat: no-repeat;\n}\n\n.sign-in-btn {\n  border: 1px solid var(--border-color-theme-18);\n  color: var(--font-color-theme-hover-1);\n}\n\n.sign-in-btn:hover {\n  background-image: linear-gradient(var(--button-background-hover-radial));\n  color: var(--font-color-primary);\n}\n\n.sign-up-btn {\n  border: 1px solid var(--border-color-theme-18);\n  color: var(--font-color-theme-hover-1);\n}\n\n.sign-up-btn:hover {\n  background-image: linear-gradient(var(--button-background-hover-radial));\n  color: var(--font-color-primary);\n}\n\n.landing_page_image {\n  max-height: 200px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
