import React, { useEffect, useState } from "react";
import "./orderdetails.css";
import { useMutation, useQuery } from "@tanstack/react-query";
import { API_URL } from "../../../services/apiConfig";
import { useLocation, useNavigate } from "react-router-dom";
import axiosInstance from "../../../services/axios.instance";
import {
  dashboard_invoice_green_tick,
  manage_my_profile_incomplete_logo,
} from "../../../assets/images";
import CustomInput from "../../CustomInput";
import { useForm } from "react-hook-form";

const GarageInsuranceOrderDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const OrderId = new URLSearchParams(location.search).get("OrderId");
  //const OrderId = state && state.OrderId;
  console.log("new", OrderId);

  const {
    data: garageOrderDetails,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["garageOrderDetails", OrderId],
    queryFn: async () => {
      const response = await axiosInstance.get(
        `${API_URL.GARAGE_ORDER_DETAILS}/${OrderId}`,
        {
          headers: {
            location: "0,0",
            language: "english",
          },
        }
      );
      return response.data;
    },
  });

  useEffect(() => {
    if (garageOrderDetails) {
      console.log("Garage Order Details:", garageOrderDetails);
      setGarageStatus(garageOrderDetails?.garage_status);
    }
  }, [garageOrderDetails]);

  //----------------------------------------------------------------------

  const mutation = useMutation({
    mutationFn: (status: string) => {
      const payload = {
        order_status: status,
        ...(status === "WORKING" && {
          delivery_partner_status: deliveryPartnerStatus,
          delivery_boy_details: {
            firstName: executiveName,
            phoneNumber: executivePhone,
            licence: executiveLicense,
          },
        }),
      };
      return axiosInstance.put(
        `${API_URL.GARAGE_STATUS_UPDATE}/${OrderId}`,
        payload,
        {
          headers: {
            location: "o,o",
            language: "english",
          },
        }
      );
    },

    onSuccess: (successResponse) => {
      console.log("successResponse", successResponse);

      navigate("/Garage/InsuranceOrder");
    },
    onError: (error: any) => {
      if (error?.data?.message) {
        //setErrorMessage(error?.data?.message);
        // setShowErrorModal(true);
      }
    },
  });

  const handleStatusChange = (status: string) => {
    console.log(`${status} button clicked`);
    const updatedDeliveryPartnerStatus =
      garageStatus === "BOOKED" ? "" : "RECEIVED";

    setDeliveryPartnerStatus(updatedDeliveryPartnerStatus);
    if (garageStatus === "WORKING" || garageStatus === "COMPLETED") {
      setExecutiveName(garageOrderDetails?.delivery_boy_details?.firstName);
      setExecutivePhone(garageOrderDetails?.delivery_boy_details?.phoneNumber);
    }
    mutation.mutate(status);
  };

  //--------------------------------------------------------------------------
  //------------------------------- delivery boy details -----

  const {
    control,
    setValue,
    getValues,
    formState: { isValid },
  } = useForm({ mode: "onChange" });

  const [executiveName, setExecutiveName] = useState("");
  const [executivePhone, setExecutivePhone] = useState("");
  const [executiveLicense, setExecutiveLicense] = useState("");
  const [garageStatus, setGarageStatus] = useState("");
  const [deliveryPartnerStatus, setDeliveryPartnerStatus] =
    useState<string>("");

  const handleExecutiveName = (value: React.SetStateAction<string>) => {
    setExecutiveName(value);
  };
  const handleExecutivePhone = (value: React.SetStateAction<string>) => {
    setExecutivePhone(value);
  };
  const handleExecutiveLicense = (value: React.SetStateAction<string>) => {
    setExecutiveLicense(value);
  };
  //---------------- delivery boy details

  const formatDate = (isoDate: any) => {
    const date = new Date(isoDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${day}-${month}-${year}`;
  };
  return (
    <div>
      <div className="col-12">
        <div className="row">
          <div className="invoice-details-btn-div text-end pt-4">
            {garageOrderDetails?.garage_status === "BOOKED" && (
              <>
                <button
                  className="btn btn-success button-order-details"
                  onClick={() => handleStatusChange("RECEIVED")}
                >
                  RECEIVED
                </button>
                &nbsp;
                <button
                  className="btn btn-danger button-order-details"
                  onClick={() => handleStatusChange("REJECTED")}
                >
                  REJECTED
                </button>
              </>
            )}
            {garageOrderDetails?.garage_status === "RECEIVED" && (
              <>
                <button
                  className="btn btn-info button-order-details"
                  onClick={() => handleStatusChange("WORKING")}
                >
                  WORKING
                </button>
                &nbsp;
                <button
                  className="btn btn-success button-order-details"
                  onClick={() => handleStatusChange("COMPLETED")}
                >
                  COMPLETED
                </button>
                &nbsp;
              </>
            )}
            {garageOrderDetails?.garage_status === "WORKING" && (
              <>
                <button
                  className="btn btn-success button-order-details"
                  onClick={() => handleStatusChange("COMPLETED")}
                >
                  COMPLETED
                </button>
                &nbsp;
              </>
            )}
          </div>
          <div className="col-md-12 text-start div-bg-invoice-details pt-3">
            <div className="card card-bg-invoice-details">
              <div className="card-body px-5">
                <div className="row  pt-4">
                  <div className="col-12 pt-3">
                    <div className="invoice-text-yellow">Order Details</div>
                  </div>
                  <div className="col-md-6 pt-3">
                    <div className="invoice-div-head">Order No</div>
                    <div className="invoice-div-data">
                      {garageOrderDetails?.orderId}
                    </div>
                  </div>
                  <div className="col-md-6 pt-3">
                    <div className="invoice-div-head">Order Date</div>
                    <div className="invoice-div-data">
                      {formatDate(garageOrderDetails?.createdAt)}{" "}
                    </div>
                  </div>
                  <div className="col-md-6 pt-3">
                    <div className="invoice-div-head">Customer Name</div>
                    <div className="invoice-div-data">
                      {garageOrderDetails?.customer_name}
                    </div>
                  </div>
                  <div className="col-md-6 pt-3">
                    <div className="invoice-div-head">
                      Customer Phone Number
                    </div>
                    <div className="invoice-div-data">
                      {garageOrderDetails?.customer_details?.phoneNumber}
                    </div>
                  </div>
                  <div className="col-md-6 pt-3">
                    <div className="invoice-div-head">Customer Email</div>
                    <div className="invoice-div-data">
                      {garageOrderDetails?.email}
                    </div>
                  </div>
                  <div className="col-md-6 pt-3">
                    <div className="invoice-div-head">Subsidiary Name</div>
                    <div className="invoice-div-data">
                      {
                        garageOrderDetails?.order_service_details
                          ?.subsidiaryName
                      }
                    </div>
                  </div>{" "}
                  <div className="col-md-6 pt-3">
                    <div className="invoice-div-head">Service Name</div>
                    <div className="invoice-div-data">
                      {" "}
                      {garageOrderDetails?.order_service_details?.serviceName}
                    </div>
                  </div>
                  <div className="col-md-6 pt-3">
                    <div className="invoice-div-head">Car Model</div>
                    <div className="invoice-div-data">
                      {" "}
                      {garageOrderDetails?.order_service_details?.carModel}
                    </div>
                  </div>
                  <div className="col-md-6 pt-3">
                    <div className="invoice-div-head">Model Year</div>
                    <div className="invoice-div-data">
                      {" "}
                      {garageOrderDetails?.order_service_details?.modelYear}
                    </div>
                  </div>
                  <div className="col-md-6 pt-3">
                    <div className="invoice-div-head">Warranty Details</div>
                    <div className="invoice-div-data">
                      {" "}
                      {
                        garageOrderDetails?.order_service_details
                          ?.warrantyDetails
                      }
                    </div>
                  </div>
                  <div className="col-md-6 pt-3">
                    <div className="invoice-div-head">Plate Number</div>
                    <div className="invoice-div-data">
                      {" "}
                      {garageOrderDetails?.plate_number}
                    </div>
                  </div>
                  <div className="col-md-6 pt-3">
                    <div className="invoice-div-head"></div>
                    <div className="invoice-div-data"></div>
                  </div>
                  <div className="row pt-3">
                    <div className="col-md-6"></div>
                    <div className="col-md-6 d-none">
                      <div className="card invoice-card-inner">
                        <div className="card-body">
                          <div className="d-flex pt-2">
                            <div className="col-1  text-center">
                              <img
                                src={dashboard_invoice_green_tick}
                                height={"18px"}
                                alt=""
                              />
                            </div>
                            <div className="col">
                              <div className=" invoice-div-head">Status</div>
                              <div className=" invoice-text-green">
                                Arrived & Handover Done
                              </div>
                            </div>
                          </div>
                          <div className="d-flex ps-2">
                            <div className="col-1 text-center hor_line"></div>
                          </div>
                          <div className="d-flex pt-2">
                            <div className="col-1  text-center">
                              <img
                                src={manage_my_profile_incomplete_logo}
                                height={"18px"}
                                alt=""
                              />
                            </div>
                            <div className="col">
                              <div className=" invoice-text-green">pending</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {garageStatus === "RECEIVED" && (
                  <div className="row pb-3">
                    <div className="col-12 pt-3">
                      <div className="invoice-text-yellow">
                        Delivery Details
                      </div>
                    </div>
                    <div className="col-md-6">
                      <label className="form-label dashboard-poppins-font-style pt-3">
                        Executive Name
                        <span className="merchant_forgot_email_red_star">
                          *
                        </span>
                      </label>
                      <CustomInput
                        inputName="executive_name"
                        inputType="text"
                        className="merchant_forgot_emailholder "
                        placeholder="Name"
                        editable={true}
                        onInputChange={handleExecutiveName}
                        control={control}
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="form-label dashboard-poppins-font-style pt-3">
                        Phone Number
                        <span className="merchant_forgot_email_red_star">
                          *
                        </span>
                      </label>
                      <CustomInput
                        inputName="phone_number"
                        inputType="text"
                        className="merchant_forgot_emailholder "
                        placeholder="Phone Number"
                        editable={true}
                        onInputChange={handleExecutivePhone}
                        control={control}
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="form-label dashboard-poppins-font-style pt-3">
                        License Number
                        <span className="merchant_forgot_email_red_star">
                          *
                        </span>
                      </label>
                      <CustomInput
                        inputName="License_number"
                        inputType="text"
                        className="merchant_forgot_emailholder "
                        placeholder="License Number"
                        editable={true}
                        onInputChange={handleExecutiveLicense}
                        control={control}
                      />
                    </div>
                  </div>
                )}

                {(garageStatus === "WORKING" ||
                  garageStatus === "COMPLETED") && (
                  <div className="row pb-3">
                    <div className="col-12 pt-3">
                      <div className="invoice-text-yellow">
                        Delivery Details
                      </div>
                    </div>
                    <div className="col-md-6 pt-3">
                      <div className="invoice-div-head">Executive Name</div>
                      <div className="invoice-div-data">
                        {garageOrderDetails?.delivery_boy_details?.firstName}
                      </div>
                    </div>
                    <div className="col-md-6 pt-3">
                      <div className="invoice-div-head">Phone Number</div>
                      <div className="invoice-div-data">
                        {garageOrderDetails?.delivery_boy_details?.phoneNumber}
                      </div>
                    </div>
                    <div className="col-md-6 pt-3">
                      <div className="invoice-div-head">Phone Number</div>
                      <div className="invoice-div-data">
                        {garageOrderDetails?.delivery_boy_details?.licence}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GarageInsuranceOrderDetails;
