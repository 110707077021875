import { useState, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  car_sign_up,
  icon_black,
  icon_normal,
  kirkos_logo,
  kirkos_merchant_arrow,
  kirkos_merchant_logo,
  kirkos_merchant_profile,
  kirkos_merchant_red_car,
  kirkos_merchant_white_car,
  kirkos_merchant_yellow_car,
} from "../../../assets/images";
import CustomInput from "../../../components/CustomInput";
import MerchantProgressbar from "../../../components/MerchantProgressbar";
import { useForm } from "react-hook-form";
import rules from "../../../rules";
import { useQueryClient, useQuery, useMutation } from "@tanstack/react-query";
import axiosInstance from "../../../services/axios.instance";
import { API_URL } from "../../../services/apiConfig";
import { UserData, MerchantOtp, VerifyOtp } from "../../../types";
import { useLocation, useNavigate } from "react-router-dom";

import SuccessModal from "../../../components/SuccessModal";
import ErrorModal from "../../../components/ErrorModal";
import { MerchantOnboardLeftSide } from "../../../components";
const GarageOtpPageViaPhone = () => {
  const [showOtpscreen, setShowOtpscreen] = useState<boolean>(false);
  const [otp, setOtp] = useState<any>(["", "", "", ""]);
  const [otpRefs] = useState<any>([useRef(), useRef(), useRef(), useRef()]);
  const [isOtpVerified, setIsOtpVerified] = useState<boolean>(false);
  const { state } = useLocation();
  const phoneNum = state && state.phoneNum;

  const transactionId = sessionStorage.getItem("transactionId");

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const navigate = useNavigate();
  const handleOtpChange = (index: number, e: any) => {
    const newOtp = [...otp];
    newOtp[index] = e.target.value;
    setOtp(newOtp);

    if (e.target.value && index < otpRefs.length - 1) {
      otpRefs[index + 1].current.focus();
    }
  };

  const handleOtpKeyDown = (index: number, e: any) => {
    if (e.key === "Backspace" && index > 0) {
      e.target.value = null;
      otpRefs[index - 1].current.focus();
    }
  };

  const {
    control,
    handleSubmit,
    formState: { isValid },
    setValue,
    trigger,
  } = useForm({ mode: "onChange" });
  const handleOtpVerify = () => {
    const payLoad: VerifyOtp = {
      phoneNo: phoneNum,
      transactionId,
      otp: "111111",
      userType: "MERCHANT",
      event: "register",
    };
    verifyOtp.mutate(payLoad);
    console.log("transactionId", transactionId);
  };

  const verifyOtp = useMutation({
    mutationFn: (userData: VerifyOtp) =>
      axiosInstance.post(API_URL.VERIFY_OTP, userData),
    onSuccess: (successResponse) => {
      console.log("successResponseOtp", successResponse);
      if (successResponse.status && successResponse?.data?.message) {
        // alert(successResponse?.data?.message);
        setIsOtpVerified(true);
        setShowSuccessModal(true);
        //navigate("./signupdetails", { state: { phoneNum: phoneNum } });
      }
    },
    onError: (error: any) => {
      if (error?.data?.message) {
        // alert(error?.data?.message);
        setShowErrorModal(true);
      }
    },
  });
  const handleResendOtp = () => {
    const userData: UserData = {
      phoneNo: phoneNum,
      userType: "MERCHANT",
      event: "register",
    };

    resendOtp.mutate(userData);
  };

  const resendOtp = useMutation({
    mutationFn: (userData: UserData) =>
      axiosInstance.post(API_URL.SEND_OTP, userData),
    onSuccess: (successResponse) => {
      console.log("successResponseResendOtp", successResponse);
      if (successResponse.status && successResponse?.data?.message) {
        setShowSuccessModal(true);
      }
    },
    onError: (error: any) => {
      if (error?.data?.message) {
        setShowErrorModal(true);
      }
    },
  });
  return (
    <>
      <div className="progressbar-custom">
        <MerchantProgressbar currentStage={2} />
      </div>
      <div className="row text-start">
        <div className="d-flex align-items-start pt-3 mt-4">
          <img
            src={kirkos_merchant_profile}
            className="custom-img-otp"
            alt="Icon"
          />
          <p className="custom-text-otp text-light mb-3 ms-3">
            OTP Verification
          </p>
        </div>
        <p className=" Merchantsubtextotp mt-2 merchant_otp_yellow_text_color">
          Please enter the OTP verification code from your phone {phoneNum}
        </p>

        <p className=" mt-2 MerchantEnterText   ">
          Enter the OTP number from your phone {phoneNum}
        </p>

        <div className="col-md-12">
          <div className="MerchantotpContainerStyle">
            <input
              type="text"
              className="otpBoxStyle"
              placeholder="0"
              maxLength={1}
              onChange={(e) => handleOtpChange(0, e)}
              onKeyDown={(e) => handleOtpKeyDown(0, e)}
              ref={otpRefs[0]}
            />
            <input
              type="text"
              className="otpBoxStyle"
              placeholder="0"
              maxLength={1}
              onChange={(e) => handleOtpChange(1, e)}
              onKeyDown={(e) => handleOtpKeyDown(1, e)}
              ref={otpRefs[1]}
            />
            <input
              type="text"
              className="otpBoxStyle"
              placeholder="0"
              maxLength={1}
              onChange={(e) => handleOtpChange(2, e)}
              onKeyDown={(e) => handleOtpKeyDown(2, e)}
              ref={otpRefs[2]}
            />
            <input
              type="text"
              className="otpBoxStyle"
              placeholder="0"
              maxLength={1}
              onChange={(e) => handleOtpChange(3, e)}
              onKeyDown={(e) => handleOtpKeyDown(3, e)}
              ref={otpRefs[3]}
            />
          </div>
        </div>
        <div className="MerchantButton mt-4">
          <div className="col-12">
            <div className="col-4">
              <button
                className="btn all_button_style text-black"
                onClick={handleSubmit(handleOtpVerify)}
                disabled={!isValid}
              >
                VERIFY
              </button>
            </div>
          </div>
        </div>
        <div className="d-flex mt-4">
          <p className="custom-otp-merchant-resend">
            Didn't receive the code?{" "}
            <a
              className="resend-link merchant_otp_yellow_text_color"
              onClick={handleResendOtp}
            >
              RESEND
            </a>
          </p>
        </div>
      </div>

      <SuccessModal
        isOpen={showSuccessModal}
        onClose={() => setShowSuccessModal(false)}
        onNav={() => {
          if (verifyOtp.isSuccess) {
            navigate("/GarageOnboard/garagePassword", {
              state: { phoneNum: phoneNum },
            });
          } else {
            setShowSuccessModal(false);
          }
        }}
        message={
          verifyOtp.isSuccess
            ? "Otp Verified Successfully"
            : "Otp Resent Successfully"
        }
      />
      <ErrorModal
        isOpen={showErrorModal}
        onClose={() => setShowErrorModal(false)}
        message={
          verifyOtp.isError ? "Otp Verification Failed" : "Resent Otp Failed"
        }
      />
    </>
  );
};

export default GarageOtpPageViaPhone;
