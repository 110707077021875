import React, { ChangeEvent, useEffect } from "react";
import "../../../Pages/Customer/signupdetails/SignUpDetailsScreen.css";
import {
  car_sign_up,
  icon_black,
  icon_normal,
  kirkos_merchant_logo,
  kirkos_merchant_profile,
  kirkos_merchant_red_car,
  kirkos_merchant_white_car,
  kirkos_merchant_yellow_car,
} from "../../../assets/images";
import iconcus from "../assets/images/kirkos_logo.svg";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  CustomCheckBox,
  CustomInput,
  MerchantOnboardLeftSide,
  Progressbar,
} from "../../../components";
import { FieldValues, Form, useForm } from "react-hook-form";
import rules from "../../../rules";
import { useState, useRef } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { API_URL } from "../../../services/apiConfig";
import axiosInstance from "../../../services/axios.instance";
import { garageSignUpdata, RegistrationData } from "../../../types";
import { useLocation, useNavigate } from "react-router-dom";
import { forEachLeadingCommentRange } from "typescript";
import { ErrorModal } from "../../../components";

import MerchantProgressbar from "../../../components/MerchantProgressbar";
import { signUpdata, Country, CountryState } from "../../../types";

interface LocationData {
  latitude: number;
  longitude: number;
}

const GarageBusinessScreen = () => {
  const [country, setCountry] = useState<string>("");
  const [state1, setState1] = useState<string>("");
  const [fetchedBusinessTypes, setFetchedBusinessTypes] = useState<
    { id: string; type: string }[]
  >([]);
  const [selectedBusinessTypeIds, setSelectedBusinessTypeIds] = useState<
    string[]
  >([]);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [businessTypesFetched, setBusinessTypesFetched] = useState(false);
  const [selectedCountryId, setSelectedCountryId] = useState<string>("");
  const [stateData, setStateData] = useState<CountryState[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [addressSuggestions, setAddressSuggestions] = useState<string[]>([]);
  const [stateTouched, setStateTouched] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState<LocationData>({
    latitude: 0,
    longitude: 0,
  });

  const navigate = useNavigate();

  const enteredData: string = localStorage.getItem("enteredData") as string;
  const parsedData = JSON.parse(enteredData);

  const {
    control,
    formState: { isValid },
    setValue,
    trigger,
    register,
    getValues,
  } = useForm({ mode: "onChange" });
  useEffect(() => {
    if (enteredData) {
      console.log("🚀 ~ useEffect ~ parsedData:", parsedData);

      setValue("businessname", parsedData.business_name || "");
      setValue("entityname", parsedData.entity_name || "");
      setValue("email", parsedData.email || "");
      setValue("phoneNo", parsedData.phone || "");
      setValue("fleetnumber", parsedData.number_of_fleets || "");
      setCountry(parsedData.country || "");
      setState1(parsedData.state || "");
      setSelectedBusinessTypeIds(parsedData.business_type || []);
    }
  }, []);

  const handleInputChange = (value: string) => {
    setSearchQuery(value);
  };

  const fetchLocationName = async (searchQuery: string) => {
    const response = await fetch(
      `https://nominatim-carhire-dev.livelocal.delivery/search.php?q=${searchQuery}&limit=5&format=json&addressdetails=1`
    );
    console.log(response, "wowww");
    const data = await response.json();
    const suggestions = data.map((item: any) => item.display_name);
    setAddressSuggestions(suggestions);
    const locationName = data?.display_name;
    return locationName;
  };

  const { data: locationName } = useQuery<string>({
    queryKey: ["locationName", searchQuery],
    queryFn: () => fetchLocationName(searchQuery),
  });
  const handleSuggestionClick = async (selectedSuggestion: string) => {
    const response = await fetch(
      `https://nominatim-carhire-dev.livelocal.delivery/search.php?q=${selectedSuggestion}&limit=5&format=json&addressdetails=1`
    );
    const data = await response.json();
    if (data.length > 0) {
      const { lat, lon } = data[0];
      setSelectedLocation({
        latitude: parseFloat(lat),
        longitude: parseFloat(lon),
      });
    }
    setValue("companyAddress", selectedSuggestion);
    setAddressSuggestions([]);
  };

  const handleSubmit = async () => {
    trigger();
    setStateTouched(true);
    if (!country || !state1 || !isValid) {
      return;
    }
    let {
      email,
      entityname: entity_name,
      businessname: business_name,
      phoneNo: phone,
      fleetnumber: number_of_fleets,
    }: FieldValues = getValues();
    const userData = {
      business_name,
      entity_name,
      email_address: email,
      number_of_fleets,
      // phone,
      country,
      state: state1,
      // business_type: selectedBusinessTypeIds,
    };

    const latitude = selectedLocation?.latitude;
    const longitude = selectedLocation?.longitude;

    localStorage.setItem("enteredData", JSON.stringify(userData));

    handleRegistration.mutate({
      merchant_signup: userData,
      shop_address: {
        address: business_name,
        latitude: latitude.toString(),
        longitude: longitude.toString(),
        state: state1,
        country: country,
      },
      mobile: phone,
      last_level: 2,
    });

    setIsFormSubmitted(true);
  };

  const handleRegistration = useMutation({
    mutationFn: (userData: garageSignUpdata) =>
      axiosInstance.put(API_URL.SIGNUP_MERCHANT, userData),

    onSuccess: (successResponse) => {
      console.log("successResponseOtp", successResponse);
      if (successResponse.status && successResponse?.data?.message) {
        navigate("/GarageOnboard/LicenseDetails");
      }
    },
    onError: (error: any) => {
      if (error?.data?.message) {
        setErrorMessage(error?.data?.message);
        setShowErrorModal(true);
      }
    },
  });

  const { mutate: fetchBusinessTypes } = useMutation({
    mutationFn: async () => {
      const response = await axiosInstance.get(API_URL.BUSINESS_TYPE);
      return response.data;
    },
    onSuccess: (successResponse) => {
      setFetchedBusinessTypes(successResponse);
    },
    onError: (error: any) => {
      console.error("Error fetching business types:", error);
    },
  });

  if (!businessTypesFetched) {
    fetchBusinessTypes();
    setBusinessTypesFetched(true);
  }

  const goForward = () => {
    navigate("/GarageOnboard/LicenseDetails");
  };

  const handleBusinessTypeChange = (id: string) => {
    setSelectedBusinessTypeIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(id)) {
        return prevSelectedIds.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevSelectedIds, String(id)];
      }
    });
  };
  const {
    data: countriesData,
    isLoading,
    isError,
  } = useQuery<Country[], Error>({
    queryKey: ["countries"],
    queryFn: async () => {
      const response = await axiosInstance.get(API_URL.COUNTRY);
      return response.data;
    },
  });

  const handleCountryChange = async (e: any) => {
    const selectedCountryName = e.target.value;
    setCountry(selectedCountryName);

    const selectedCountry = countriesData?.find(
      (country) => country.countryName === selectedCountryName
    );

    if (selectedCountry) {
      setSelectedCountryId(selectedCountry.countryId.toString());

      const response = await axiosInstance.get(
        `${API_URL.STATE}${selectedCountry.countryId}`
      );
      setStateData(response.data);
    }
  };

  return (
    <>
      <Form onSubmit={handleSubmit} control={control}>
        <div className="progressbar-custom">
          <MerchantProgressbar currentStage={4} />
        </div>
        <div className="row text-start  mt-5">
          <div className="row">
            <div className="col-md-12">
              <img
                src={kirkos_merchant_profile}
                className="icon-img img-fluid mb-3 "
                alt="Icon"
              />
              <span className="text-light custom-font-signup">
                {" "}
                &nbsp;Garage Sign Up
              </span>
            </div>
            <div className="row">
              <div className="col-md-10">
                <p className="mt-1 text-start merchant_business_yellow_text_color">
                  Basic contact information with minimum fields needs to be
                  filled below
                </p>
              </div>
            </div>
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-12 onboard-inner-div-scroll">
                  {/* <div className=" input-container-custom-business mt-4"> */}
                  <div className="col-md-12 ">
                    <label className="text-light mt-3 business_page_right_side_font">
                      Business Name
                      <label className="text-danger ms-0 p-0">*</label>
                    </label>

                    <CustomInput
                      inputName="businessname"
                      inputType="businessname"
                      className="merchantinputStylex"
                      placeholder="Eg: Skyline"
                      editable={true}
                      onInputChange={(value: string) => {}}
                      control={control}
                      rules={rules.AuthRules.businessname}
                    />
                  </div>
                  <div className="col-md-12">
                    <label className="text-light mt-3 business_page_right_side_font">
                      Entity Name
                      <label className="text-danger ms-0 p-0">*</label>
                    </label>
                    <CustomInput
                      inputName="entityname"
                      inputType="entityname"
                      editable={true}
                      control={control}
                      className="merchantinputStylex"
                      placeholder="Eg: PVT, LLC..."
                      onInputChange={(value: string) => {}}
                      rules={rules.AuthRules.entityname}
                    />
                  </div>

                  <div className="col-md-12">
                    <label className="text-light mt-4 business_page_right_side_font">
                      Email Address{" "}
                      <label className="text-danger ms-0 p-0">*</label>
                    </label>

                    <CustomInput
                      inputName="email"
                      inputType="email"
                      className="merchantinputStylex"
                      placeholder="abc@gmail.com"
                      editable={true}
                      onInputChange={(value: string) => {}}
                      control={control}
                      rules={rules.AuthRules.email}
                    />
                  </div>

                  <div className="col-md-12">
                    <label className="text-light mt-3 business_page_right_side_font">
                      Phone Number{" "}
                      <label className="text-danger ms-0 p-0">*</label>
                    </label>
                    <div className="inputContainerStyle">
                      <span className="garage_new_country_codestyle ">
                        +971
                      </span>
                      <CustomInput
                        inputName="phoneNo"
                        inputType="text"
                        className="merchantinputStylex"
                        placeholder="enter your number"
                        editable={true}
                        onInputChange={(value: string) => {}}
                        control={control}
                        maxLength={9}
                        // rules={rules.AuthRules.phone_number}
                        rules={{
                          ...rules.AuthRules.phone_number,
                          minLength: 9,
                          maxLength: 9,
                          validate: (value: string | any[]) =>
                            value.length === 9 ||
                            "Phone number must be exactly 9 digits",
                        }}
                      />
                    </div>
                  </div>
                  {/* <div className="col-md-12 ">
                    <label className="text-light pt-3 business_page_right_side_font">
                      Types of Business{" "}
                      <label className="text-danger ms-0 p-0">*</label>
                    </label>

                    <div className="row MerchantrememberMeContainer pt-3">
                      {fetchedBusinessTypes &&
                        fetchedBusinessTypes.map((businessType) => (
                          <React.Fragment key={businessType.id}>
                            <div className="col-md-6 d-flex">
                              <CustomCheckBox
                                isChecked={selectedBusinessTypeIds.includes(
                                  String(businessType.id)
                                )}
                                onChange={() =>
                                  handleBusinessTypeChange(
                                    String(businessType.id)
                                  )
                                }
                              />
                              <label className="rememberMeText ">
                                {businessType.type}
                              </label>
                            </div>
                          </React.Fragment>
                        ))}
                    </div>
                    {stateTouched && !selectedBusinessTypeIds.length && (
                      <span className="error-message text-danger d-flex justify-content-end">
                        required
                      </span>
                    )}
                  </div> */}
                  <div className="col-md-12 pt-3 ">
                    <label className="text-light  business_page_right_side_font">
                      Number of Fleets
                      <label className="text-danger ms-0 p-0">*</label>
                    </label>
                    <CustomInput
                      inputType="Numbers"
                      inputName="fleetnumber"
                      control={control}
                      editable={true}
                      className="merchantinputStylex"
                      placeholder=""
                      onInputChange={(value: string) => {}}
                      rules={rules.AuthRules.fleetnumber}
                    />
                  </div>

                  <div className="col-md-12">
                    <div className="row ">
                      <div className="col-md-6">
                        <label className="form-label text-light  mt-4 business_page_right_side_font">
                          Country{" "}
                          <label className="text-danger ms-0 p-0">*</label>
                        </label>
                        <select
                          className="merchantinputStylex customWidths select-business-style"
                          value={country}
                          onChange={handleCountryChange}
                        >
                          <option
                            value=""
                            key="select"
                            className="business_page_right_side_font"
                          >
                            Select country
                          </option>
                          {countriesData &&
                            countriesData.map((country) => (
                              <option
                                key={country.countryId}
                                value={country.countryName}
                              >
                                {country.countryName}
                              </option>
                            ))}
                        </select>
                        {stateTouched && !country && (
                          <span className="error-message text-danger d-flex justify-content-end">
                            required
                          </span>
                        )}
                      </div>
                      <div className="col-md-6  ">
                        <label className="form-label text-light mt-4  business_page_right_side_font">
                          State<label className="text-danger ">*</label>
                        </label>
                        <select
                          className="merchantinputStylex customWidths select-business-style"
                          value={state1}
                          onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                            setState1(e.target.value)
                          }
                        >
                          <option
                            value=""
                            key="select"
                            className="business_page_right_side_font"
                          >
                            Select state
                          </option>
                          {stateData &&
                            stateData.map((state) => (
                              <option key={state.id} value={state.state_name}>
                                {state.state_name}
                              </option>
                            ))}
                        </select>
                        {stateTouched && !state1 && (
                          <span className="error-message text-danger d-flex justify-content-end">
                            required
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 pt-3 relative-div">
                    <label className="text-light  business_page_right_side_font ">
                      Company Address
                      <label className="text-danger ms-0 p-0">*</label>
                    </label>
                    <CustomInput
                      inputType="companyAddress"
                      inputName="companyAddress"
                      control={control}
                      editable={true}
                      className="merchantinputStylex"
                      placeholder=""
                      onInputChange={(value: string) =>
                        handleInputChange(value)
                      }
                      rules={rules.AuthRules.companyAddress}
                    />
                    {addressSuggestions.length > 0 && (
                      <div className="absolute-div">
                        <ul className="text-start" style={{ color: "black" }}>
                          {addressSuggestions.map((suggestion, index) => (
                            <li
                              key={index}
                              onClick={() => handleSuggestionClick(suggestion)}
                            >
                              {suggestion}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="col-md-12">
                    <button
                      className="btn all_button_style text-black mt-3  mb-3 "
                      // disabled={
                      //   !isValid ||
                      //   !selectedBusinessTypeIds.length ||
                      //   !country ||
                      //   !state1
                      // }
                      type="submit"
                    >
                      SUBMIT
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-md-12 pt-3">
                    <div className="page_navigator">
                      <div>
                        <button className="prevBtn">&lt;</button>
                      </div>
                      <div className="page-navigator ms-1">
                        <button className="nextBtn" onClick={goForward}>
                          &gt;
                        </button>
                      </div>
                    </div>
                  </div> */}
          </div>
        </div>

        <ErrorModal
          isOpen={showErrorModal}
          onClose={() => setShowErrorModal(false)}
          message={errorMessage}
        />
      </Form>
    </>
  );
};

export default GarageBusinessScreen;
