// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.merchantprogressContainer {
  display: flex;
  align-items: center;
  /* margin-left: 50px; */
  margin-top: 40px;
}

.merchantprogressLine {
  min-width: 3vw;
  min-height: 0.1vw;
  background-color: var(--bg-colour-theme-26);
}

.merchantprogressCircle {
  min-width: 0.8vw;
  min-height: 0.8vw;
  border-radius: 50%;
  /* background-color: var(--bg-colour-theme-26); */
  background-color: transparent;
  border: 1px solid var(--bg-colour-theme-26);
  margin: 0 1vw;
  transition: background-color 0.3s ease;
}

.merchantprogressCircle.initialActive {
  background-color: var(--border-color-theme-4);
  border: 1px solid var(--border-color-theme-4);
}

@media only screen and (max-width: 768px) {
  .merchantprogressContainer {
    margin-left: 5vw;
    width: 90%;
  }

  .merchantprogressLine {
    width: 20%;
  }

  .merchantprogressCircle {
    margin: 0 1vw;
  }

  .merchantprogressCircle.initialActive {
    width: 1vw;
    height: 1vw;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/MerchantProgressbar/MerchantprogressbarStyle.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,iBAAiB;EACjB,2CAA2C;AAC7C;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;EAClB,iDAAiD;EACjD,6BAA6B;EAC7B,2CAA2C;EAC3C,aAAa;EACb,sCAAsC;AACxC;;AAEA;EACE,6CAA6C;EAC7C,6CAA6C;AAC/C;;AAEA;EACE;IACE,gBAAgB;IAChB,UAAU;EACZ;;EAEA;IACE,UAAU;EACZ;;EAEA;IACE,aAAa;EACf;;EAEA;IACE,UAAU;IACV,WAAW;EACb;AACF","sourcesContent":[".merchantprogressContainer {\r\n  display: flex;\r\n  align-items: center;\r\n  /* margin-left: 50px; */\r\n  margin-top: 40px;\r\n}\r\n\r\n.merchantprogressLine {\r\n  min-width: 3vw;\r\n  min-height: 0.1vw;\r\n  background-color: var(--bg-colour-theme-26);\r\n}\r\n\r\n.merchantprogressCircle {\r\n  min-width: 0.8vw;\r\n  min-height: 0.8vw;\r\n  border-radius: 50%;\r\n  /* background-color: var(--bg-colour-theme-26); */\r\n  background-color: transparent;\r\n  border: 1px solid var(--bg-colour-theme-26);\r\n  margin: 0 1vw;\r\n  transition: background-color 0.3s ease;\r\n}\r\n\r\n.merchantprogressCircle.initialActive {\r\n  background-color: var(--border-color-theme-4);\r\n  border: 1px solid var(--border-color-theme-4);\r\n}\r\n\r\n@media only screen and (max-width: 768px) {\r\n  .merchantprogressContainer {\r\n    margin-left: 5vw;\r\n    width: 90%;\r\n  }\r\n\r\n  .merchantprogressLine {\r\n    width: 20%;\r\n  }\r\n\r\n  .merchantprogressCircle {\r\n    margin: 0 1vw;\r\n  }\r\n\r\n  .merchantprogressCircle.initialActive {\r\n    width: 1vw;\r\n    height: 1vw;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
