import { useState } from "react";
import "./styles.css";
import {
  vehicle_inspection_car,
  vehicle_inspection_img,
  star,
} from "../../../assets/images";
import CustomRadio from "../../customRadio";
import { useLocation, useNavigate } from "react-router-dom";
import axiosInstance from "../../../services/axios.instance";
import { useMutation } from "@tanstack/react-query";
import { API_URL } from "../../../services/apiConfig";
import RentalSummarySecondPage from "../RentalSummarySecondPage";
import { useSelector, useDispatch } from "react-redux"; // Import useSelector and useDispatch
import { setBookingValues } from "../../../Redux/BookingData/bookingDataSlice"; // Import your action

const VehicleInspection = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [deliveryFee, setDeliveryFee] = useState<number>(0);
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);

  const {
    deliveryDetails,
    optionalData,
    selectedItem,
    merchantId,
    searchData,
  } = location.state;

  const [selectedOptions, setSelectedOptions] = useState<string>("");
  const { bookingValues } = useSelector((state: any) => state.bookingValues);

  // const handleRadioChange = (value: string) => {
  //   // setSelectedOptions(value);
  //   // Dispatch action to update booking values in Redux
  //   // dispatch(setBookingValues({ selectedOptions: value }));
  //   dispatch(setBookingValues({ ...bookingValues, deliveryAmount: value }));
  // };

  //---------------------------------------------------------------------------------------

  const handleRadioChange = (value: string) => {
    dispatch(setBookingValues({ ...bookingValues, deliveryAmount: value }));
    setIsButtonDisabled(false);

    if (value === "No") {
      mutation.mutate({
        merchant_latitude: selectedItem?.merchant_latitude,
        merchant_longitude: selectedItem?.merchant_longitude,
        customer_latitude: searchData?.pickupLocLatitude,
        customer_longitude: searchData?.pickupLocLongitude,
        amount: 0,
        delivery_type: "STANDARD",
      });
    }
  };

  const accessToken = localStorage.getItem("access_token");

  // const decodeToken = (token: any) => {
  //   return JSON.parse(atob(token.split(".")[1]));
  // };
  const decodeToken = (token: any) => {
    if (token) {
      return JSON.parse(atob(token.split(".")[1]));
    } else {
      return null;
    }
  };

  const decodedToken = decodeToken(accessToken);

  const businessArea = decodedToken["planet"];

  //-----------------------
  const mutation = useMutation({
    mutationFn: (userData: any) =>
      axiosInstance.post(API_URL.DELIVERY_FEE, userData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          location: `0,0`,
        },
      }),

    onSuccess: (successResponse) => {
      console.log("successResponseproductlist", successResponse);
      const deliveryFee = successResponse?.data?.delivery_fee; // Extract delivery fee from success response
      setDeliveryFee(deliveryFee);
    },
    onError: (error: any) => {
      if (error?.data?.message) {
        //alert(error?.data?.message);
      }
    },
  });

  //------------------------------------------------------------------------------------

  const cdwDailyRate =
    selectedItem?.product_car_insurance?.collision_damage_waiver_model
      ?.daily_collision_damage_waiver;
  const daysDifference = deliveryDetails?.daysDifference;

  let cdwAmount = 0;
  if (cdwDailyRate !== undefined && daysDifference !== undefined) {
    cdwAmount = cdwDailyRate * daysDifference;
  }

  const scdwDailyRate =
    selectedItem.product_car_insurance?.super_collision_damage_waiver_model
      ?.daily_super_collision_damage_waiver;

  let scdwAmount = 0;
  if (scdwDailyRate !== undefined && daysDifference !== undefined) {
    scdwAmount = scdwDailyRate * daysDifference;
  }

  const handleConfirmation = () => {
    const deliveredBy =
      selectedOptions === "Yes" ? "CUSTOMER" : "KIRKOS_EXECUTIVE";

    const deliveryData = {
      deliveredBy,
    };

    navigate("/Customer/processpayment", {
      state: {
        deliveryData,
        deliveryDetails,
        optionalData,
        selectedItem,
        merchantId,
        deliveryFee,
      },
    });
  };

  return (
    <div className="row">
      <div className="col-md-8 d-flex px-3">
        <div className="row ">
          <div className="col-md-12 px-4">
            <div className="row main-vi">
              <div className="row">
                <div className="d-flex text-start pt-4">
                  <img src={vehicle_inspection_car} alt="Logo" className=" " />
                  <div className="vehicle_inspection_text ps-3 mt-2">
                    Pick up from location or collect
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="row  ps-5 text-start">
                    <div className="col-md-6 pt-3">
                      <div className="d-flex">
                        <label className="radio_button_text">
                          Pick up by customer
                        </label>
                        <div className="ps-2">
                          <CustomRadio
                            onChange={() => handleRadioChange("Yes")}
                            //checked={selectedOptions === "Yes"}
                            checked={bookingValues?.deliveryAmount === "Yes"}
                            label=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6  pt-3">
                      <div className="d-flex">
                        <label className="radio_button_text">
                          Deliver by kirkos executive
                        </label>
                        <div className="ps-2">
                          <CustomRadio
                            onChange={() => handleRadioChange("No")}
                            // checked={selectedOptions === "No"}
                            checked={bookingValues?.deliveryAmount === "No"}
                            label=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="d-flex mt-2  ">
                  <img src={vehicle_inspection_img} alt="Logo" className="" />
                  <div className="ps-3">
                    <div className="vehicle_inspection_text pt-2">
                      Vehicle Inspection
                    </div>
                  </div>
                </div>
              </div>
              <div className="row ps-4">
                <div className="col-md-12 pt-2 text-start">
                  <img src={star} alt="Logo" className="" />
                  <span className=" descripton_vi">
                    &nbsp;&nbsp;While pickup from location the Kirkos executive
                    and Customer will do a vehicle check and inspection mutually
                    and record / document the check
                  </span>
                </div>
              </div>
              <div className="col-md-12 py-4 text-end">
                <button
                  type="button"
                  className="btn all_button_style text-black my-1"
                  onClick={handleConfirmation}
                  disabled={isButtonDisabled}
                >
                  <span className="mx-2">CONFIRM</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-4">
        <RentalSummarySecondPage
          daysDifference={deliveryDetails.daysDifference}
          totalAmount={deliveryDetails.totalAmount}
          childSeatAmount={selectedItem.product_details_car.child_seat_amount}
          onDataFromRentalSummary={(data: any) => {
            console.log("Data from Rental Summary:", data);
          }}
          paiInsuranceAmount={
            selectedItem.product_car_insurance
              ?.personal_accident_insurance_model
              ?.daily_personal_accident_insurance
          }
          carImage={selectedItem.product_details.images[0]}
          carModel={selectedItem.product_details_car.car_model}
          seat={selectedItem.product_details_car.number_of_seats}
          fuelType={selectedItem.product_details_car.fuel_type}
          // cdwAmount={
          //   selectedItem.product_car_insurance?.collision_damage_waiver_model
          //     ?.daily_collision_damage_waiver
          // }
          cdwAmount={cdwAmount}
          scdwAmount={scdwAmount}
          deliveryFee={deliveryFee}
          // scdwAmount={
          //   selectedItem.product_car_insurance
          //     ?.super_collision_damage_waiver_model
          //     ?.daily_super_collision_damage_waiver
          // }
        />
      </div>
    </div>
  );
};

export default VehicleInspection;
