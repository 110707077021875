// CustomCheckBox.tsx
import React from "react";

interface CustomCheckBoxProps {
  isChecked: boolean;
  onChange: () => void;
}

const CustomCheckBox: React.FC<CustomCheckBoxProps> = ({
  isChecked,
  onChange,
}) => {
  return (
    <label
      style={{
        display: "block",
        position: "relative",
        paddingLeft: "25px",
        marginBottom: "13%",
        cursor: "pointer",
        fontSize: "18px",
        WebkitUserSelect: "none",
        MozUserSelect: "none",
        msUserSelect: "none",
        userSelect: "none",
      }}
    >
      <input
        type="checkbox"
        checked={isChecked}
        onChange={onChange}
        style={{
          position: "absolute",
          opacity: 0,
          cursor: "pointer",
          height: 0,
          width: 0,
        }}
      />
      <span
        className="checkmark"
        style={{
          position: "absolute",
          top: "2px",
          left: "2px",
          height: "18px",
          width: "18px",
          backgroundColor: isChecked ? "" : "transparent",
          border: "1px solid #F4B550",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {isChecked && (
          <svg
            viewBox="0 0 24 24"
            style={{ fill: "none", stroke: "#000", strokeWidth: "3" }}
          >
            <path d="M5 12L10 17L19 8"></path>
          </svg>
        )}
      </span>
    </label>
  );
};

export default CustomCheckBox;

// import React, { useState } from "react";

// const CustomCheckBox = () => {
//   const [isChecked, setIsChecked] = useState(false);

//   const handleCheckboxChange = () => {
//     setIsChecked(!isChecked);
//   };

//   return (
//     <label
//       style={{
//         display: "block",
//         position: "relative",
//         paddingLeft: "25px",
//         marginBottom: "15%",
//         cursor: "pointer",
//         fontSize: "18px",
//         WebkitUserSelect: "none",
//         MozUserSelect: "none",
//         msUserSelect: "none",
//         userSelect: "none",
//       }}
//     >
//       <input
//         type="checkbox"
//         checked={isChecked}
//         onChange={handleCheckboxChange}
//         style={{
//           position: "absolute",
//           opacity: 0,
//           cursor: "pointer",
//           height: 0,
//           width: 0,
//         }}
//       />
//       <span
//         className="checkmark"
//         style={{
//           position: "absolute",
//           top: "2px",
//           left: "2px",
//           height: "18px",
//           width: "18px",
//           backgroundColor: isChecked ? "" : "transparent",
//           border: "1px solid #F4B550",
//           display: "flex",
//           alignItems: "center",
//           justifyContent: "center",
//         }}
//       >
//         {/* Only show the tick mark when isChecked is true */}
//         {isChecked && (
//           <svg
//             viewBox="0 0 24 24"
//             style={{ fill: "none", stroke: "#fff", strokeWidth: "3" }}
//           >
//             <path d="M5 12L10 17L19 8"></path>
//           </svg>
//         )}
//       </span>
//     </label>
//   );
// };

// export default CustomCheckBox;
